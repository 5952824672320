// Apollo Setup Docs - https://www.apollographql.com/docs/react/get-started/

import React, { createContext, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ApolloClient from 'apollo-client';
import { WebSocketLink } from 'apollo-link-ws';
import { HttpLink } from 'apollo-link-http';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { ApolloProvider } from 'react-apollo';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
// import { onError } from 'apollo/client/link/error';
import * as Sentry from '@sentry/react';
import _sdk from '@hopdrive/sdk';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import {
  getUserName,
  getUserEmail,
  getAllowedRoles,
  getAllowedCustomers,
  getUserRole,
  getCustomerId,
} from './components/utils/authHelper.js';

const version = require('./version.js');

const DataContext = createContext({});

function DataProvider({ children }) {
  const log = false;

  const firebaseAuth = getAuth();
  const history = useHistory();

  const [firebaseUser, setFirebaseUser] = React.useState(null);
  const [apolloClient, setApolloClient] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [lastLocation, setLastLocation] = React.useState(null);
  const [allowedCustomers, setAllowedCustomers] = React.useState([]);
  const [customerOverride, setCustomerOverride] = React.useState(null);
  const [customerId, setCustomerId] = React.useState(null);
  const [role, setRole] = React.useState(null);

  //Listen for changes to the logged in Firebase user and set JWT accordingly

  const getFirebaseIdToken = async () => {
    let firebaseToken = firebaseAuth && firebaseAuth.currentUser ? await firebaseAuth.currentUser.getIdToken() : null;
    if (firebaseToken) {
      setFirebaseUser(firebaseToken);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    onAuthStateChanged(firebaseAuth, user => {
      getFirebaseIdToken();
      if (!user) {
        handleApolloClient();
      }
    });
  }, []);

  const retrieveCustomerId = async () => {
    let customer = await getCustomerId();
    setCustomerId(customer);
  };

  React.useEffect(() => {
    const retrieveAllowedCustomers = async () => {
      let customers = await getAllowedCustomers();
      setAllowedCustomers(customers);
    };

    const retrieveRole = async () => {
      let userRole = await getUserRole();
      setRole(userRole);
    };

    if (firebaseUser) {
      retrieveAllowedCustomers();
      retrieveCustomerId();
      retrieveRole();
    }
  }, [firebaseUser]);

  React.useEffect(() => {
    if (role && role === 'dealer' && customerId) {
      localStorage.setItem('selectedCustomerId', customerId);
      setCustomerOverride(customerId);
    } else if (role && role === 'dealer-admin' && customerId) {
      if (localStorage.getItem('selectedCustomerId')) {
        setCustomerOverride(parseInt(localStorage.getItem('selectedCustomerId')));
      } else {
        localStorage.setItem('selectedCustomerId', customerId);
        setCustomerOverride(customerId);
      }
    } else if (role && role === 'admin') {
      if (localStorage.getItem('selectedCustomerId')) {
        setCustomerOverride(parseInt(localStorage.getItem('selectedCustomerId')));
      } else {
        localStorage.setItem('selectedCustomerId', 9);
        setCustomerOverride(0);
      }
    }
  }, [role, customerId]);

  //If we have a JWT, use it to build out the Sentry user and the user object to be used as context throughout the site
  //Then, initialize Apollo with the JWT
  useEffect(() => {
    const buildSentryUser = async () => {
      return {
        email: await getUserEmail(),
        name: await getUserEmail(),
        nickname: await getUserName(),
        role: await getUserRole(),
        allowed_roles: await getAllowedRoles(),
      };
    };

    const SentryUser = buildSentryUser();
    Sentry.setContext('user', SentryUser);

    if (firebaseUser !== null) {
      handleApolloClient();
    }
  }, [firebaseUser]);

  const handleApolloClient = async () => {
    console.log(`Attempting to initialize Apollo...`);

    //Set up error handler to refresh JWT if it's expired
    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          if (extensions && extensions.code && extensions.code === 'invalid-headers') {
            console.error(`[GraphQL Error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            firebaseAuth.signOut(firebaseAuth);
            setFirebaseUser(null);
          }
          if (message && message.includes('JWTExpired')) {
            if (firebaseAuth.currentUser) {
              getFirebaseIdToken();
            } else {
              console.error(`[GraphQL Error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
              firebaseAuth.signOut(firebaseAuth);
              history.push('/login');
            }
          }
        });
      }

      if (networkError) {
        console.error(`[Network Error]: ${networkError.message}`);
      }
    });

    const wsurl = process.env.REACT_APP_GRAPHQL_WSS_URL;
    const httpUrl = process.env.REACT_APP_GRAPHQL_URL;

    const httpLink = new HttpLink({
      uri: httpUrl,
    });

    const getHeaders = async () => {
      const clientName = `dealer-portal-${version.tag || 'vX.X.X'}`;
      try {
        const token = firebaseUser;
        return {
          Authorization: `Bearer ${token}`,
          'hasura-client-name': clientName,
        };
      } catch (err) {
        console.log('Error retrieving token', err);
      }
    };

    //Setup authlink with id token jwt
    const authLink = setContext(async () => {
      return { headers: await getHeaders() };
    });

    //Web Socket setup (for subscriptions)
    const wsLink = new WebSocketLink({
      uri: wsurl,
      options: {
        lazy: true,
        reconnect: true,
        timeout: 30000,
        connectionParams: async () => {
          return { headers: await getHeaders() };
        },
      },
    });

    const link = split(
      // split based on operation type
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      wsLink,
      authLink.concat(httpLink)
    );

    const client = new ApolloClient({
      link: ApolloLink.from([errorLink, link]),
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: 'network-only',
        },
      },
      connectToDevTools: true,
    });

    console.log(`Apollo Initialized!`, client);

    //Configure sdk to use apolloClient with user's token
    _sdk.configure({
      apollo_client: client,
    });

    setApolloClient(client);
  };

  //Set Context
  const context = {
    apolloClient,
    sdk: _sdk,
    firebaseUser,
    setFirebaseUser,
    loading,
    setLoading,
    lastLocation,
    setLastLocation,
    allowedCustomers,
    customerOverride,
    setCustomerOverride,
    customerId,
    role,
  };

  return (
    <DataContext.Provider value={context}>
      {apolloClient ? <ApolloProvider client={apolloClient}>{children}</ApolloProvider> : null}
    </DataContext.Provider>
  );
}

const useData = () => useContext(DataContext);

export { useData, DataProvider };
