//////////////////////// DEPENDENCIES ////////////////////////

import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

//////////////////////// HOOK ////////////////////////

export function useTools() {
  const history = useHistory();

  // Travel to different pages
  const goToRoute = (route = null) => {
    if (route) history.push(route);
    else toast.error(`No route was specified!`);
  };
  const goToPreviousPage = () => {
    history.goBack();
  };
  const goToNextPage = () => {
    history.goForward();
  };
  const goToMovesIndex = () => {
    history.push(`/moves`);
  };
  const goToLanesIndex = () => {
    history.push(`/lanes`);
  };
  const goToLocationsIndex = () => {
    history.push(`/locations`);
  };
  const goToMovePlanner = () => {
    history.push(`/moves/add`);
  };
  const goToMoveDetails = (moveId = null, edit = false) => {
    // Provides the ability to jump into editMode on move details
    if (moveId && edit)
      history.push({
        pathname: `/moves/${moveId}`,
        state: { editMode: true },
      });
    else if (moveId) history.push(`/moves/${moveId}`);
    else toast.error(`No move ID was specified!`);
  };
  const goToInvoice = (invoiceId = null, customerId = null, foldId = null) => {
    if (invoiceId) localStorage.setItem('invoice-id', invoiceId);
    if (customerId) localStorage.setItem('invoice-customer-id', customerId);
    if (foldId) localStorage.setItem('invoice-fold-id', foldId);
    history.push(`/invoices`);
  };

  const goToUserDetails = (userId = null) => {
    if (userId) history.push(`/users/${userId}`);
    else toast.error(`No user ID was specified!`);
  };

  /** Breakdown a string of text to its simplest form (remove all formatting and spacing) */
  const condensedCase = (str = null) => {
    if (str && typeof str === `string`) {
      const newStr = str
        .replace(/[,.?!:;`'"/{}()<>\s_+-]/g, ``)
        .toLowerCase()
        .trim();
      return newStr;
    }
    return str;
  };

  /** Capitalize the first letter of the first word */
  const capFirst = (str = null) => {
    if (str && typeof str === `string`) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
  };

  /** Capitalize the first letter of each word */
  const capEach = (str = null) => {
    if (str && typeof str === `string`) {
      if (!str.includes(` `)) return str.charAt(0).toUpperCase() + str.slice(1);
      else {
        let arr = str.split(` `);
        arr = arr.map(s => s.charAt(0).toUpperCase() + s.slice(1));
        return arr.join(` `);
      }
    }
    return str;
  };

  /** Capitalize every letter of every word */
  const capAll = (str = null) => {
    if (str && typeof str === `string`) {
      return str.toUpperCase();
    }
    return str;
  };

  /** Round a number with a precision */
  const round = (num = null, precision = 0) => {
    if (num) {
      const multiplier = Math.pow(10, precision);
      const output = Math.round(num * multiplier) / multiplier;
      return output;
    }
    return 0;
  };
  /** Detect if a number is negative */
  const checkNeg = num => {
    if (num > 0) return num;
    else return 0;
  };

  /** Get human readable name from rideshare partner */
  const getFormattedRidesharePartner = (str = null) => {
    if (str) {
      const string = str.toLowerCase();
      if (string === `lyft` || string === `l`) return `Lyft`;
      if (string === `uber` || string === `u`) return `Uber`;
      if (string === `auto` || string === `a`) return `Auto`;
      if (string === `hopdrive` || string === `h` || string === `hd`) return `HopDrive`;
    }
    return null;
  };

  /** Get formatted combined status string from a drive or ride */
  const getFormattedCombinedStatusFromMove = (move = null) => {
    // Default fallback status string when not enough info is provided
    let fallbackStatus = `Unknown`;

    // Check for move
    if (move) {
      // Set local variables
      fallbackStatus = move.move_type === `drive` ? `Not Assigned` : `Not Started`;
      const cancelStatus = move && move.cancel_status ? move.cancel_status.toLowerCase() : null;
      const status = move && move.status ? move.status.toLowerCase() : null;

      // Check statuses and return a formatted string
      if (cancelStatus) {
        if (cancelStatus === `pending`) return `Pending Cancel`;
        if (cancelStatus === `seen`) return `Cancel Was Seen`;
        if (cancelStatus === `canceled`) return `Cancel Before Started`;
        if (cancelStatus === `started`) return `Cancel After Started`;
        if (cancelStatus === `delivered`) return `Cancel After Delivered`;
        return capEach(cancelStatus);
      }
      if (status) {
        if (status === `dispatched`) return `Dispatched`;
        if (status === `pickup started` || status === `pickup_started`) return `Pickup Started`;
        if (status === `pickup arrived` || status === `pickup_arrived`) return `Pickup Arrived`;
        if (status === `pickup successful` || status === `pickup_successful`) return `Pickup Successful`;
        if (status === `delivery started` || status === `delivery_started`) return `Delivery Started`;
        if (status === `delivery arrived` || status === `delivery_arrived`) return `Delivery Arrived`;
        if (status === `delivery successful` || status === `delivery_successful`) return `Delivery Successful`;
        if (status === `awaitingresponse` || status === `awaiting response` || status === `awaiting_response`)
          return `Awaiting Response`;
        if (status === `accepted`) return `Accepted`;
        if (status === `arrived`) return `Arrived`;
        if (status === `pickedup` || status === `picked up` || status === `picked_up`) return `Picked Up`;
        if (status === `droppedoff` || status === `dropped off` || status === `dropped_off`) return `Dropped Off`;
        if (status === `canceled`) return `Canceled`;
        if (status === `failed`) return `Failed`;
        return capEach(status);
      }
    }
    return fallbackStatus;
  };

  /** Get formatted status string from a drive */
  const getFormattedStatusFromDrive = (drive = null) => {
    // Default fallback status string when not enough info is provided
    let fallbackStatus = `Unknown`;

    // Check for drive
    if (drive) {
      // Set local variables
      fallbackStatus = `Not Assigned`;
      const status = drive && drive.status ? drive.status.toLowerCase() : null;

      // Check statuses and return a formatted string
      if (status) {
        if (status === `dispatched`) return `Dispatched`;
        if (status === `pickup started` || status === `pickup_started`) return `Pickup Started`;
        if (status === `pickup arrived` || status === `pickup_arrived`) return `Pickup Arrived`;
        if (status === `pickup successful` || status === `pickup_successful`) return `Pickup Successful`;
        if (status === `delivery started` || status === `delivery_started`) return `Delivery Started`;
        if (status === `delivery arrived` || status === `delivery_arrived`) return `Delivery Arrived`;
        if (status === `delivery successful` || status === `delivery_successful`) return `Delivery Successful`;
        if (status === `canceled`) return `Canceled`;
        if (status === `failed`) return `Failed`;
        return capEach(status);
      }
    }
    return fallbackStatus;
  };

  /** Get formatted cancel status string from a drive */
  const getFormattedCancelStatusFromDrive = (drive = null) => {
    // Default fallback status string when not enough info is provided
    let fallbackStatus = `Unknown`;

    // Check for drive
    if (drive) {
      // Set local variables
      fallbackStatus = `Not Canceled`;
      const cancelStatus = drive && drive.cancel_status ? drive.cancel_status.toLowerCase() : null;

      // Check statuses and return a formatted string
      if (cancelStatus) {
        if (cancelStatus === `pending`) return `Pending Cancel`;
        if (cancelStatus === `seen`) return `Cancel Was Seen`;
        if (cancelStatus === `canceled`) return `Cancel Before Started`;
        if (cancelStatus === `started`) return `Cancel After Started`;
        if (cancelStatus === `delivered`) return `Cancel After Delivered`;
        return capEach(cancelStatus);
      }
    }
    return fallbackStatus;
  };

  /** Get formatted status string from a ride */
  const getFormattedStatusFromRide = (ride = null) => {
    // Default fallback status string when not enough info is provided
    let fallbackStatus = `Unknown`;

    // Check for ride
    if (ride) {
      // Set local variables
      fallbackStatus = `Not Called`;
      const status = ride.status ? ride.status.toLowerCase() : null;

      // Check statuses and return a formatted string
      if (status) {
        if (status === `awaitingresponse` || status === `awaiting response` || status === `awaiting_response`)
          return `Awaiting Response`;
        if (status === `accepted`) return `Accepted`;
        if (status === `arrived`) return `Arrived`;
        if (status === `pickedup` || status === `picked up` || status === `picked_up`) return `Picked Up`;
        if (status === `droppedoff` || status === `dropped off` || status === `dropped_off`) return `Dropped Off`;
        if (status === `canceled`) return `Canceled`;
        if (status === `failed`) return `Failed`;
        return capEach(status);
      }
    }
    return fallbackStatus;
  };

  const getFormattedStatusFromPlan = plan => {
    if (!plan || !plan.moves) return null;

    //Find first move whose delivery_time is not in the past
    let currentMove = plan.moves.find(m => moment(m.delivery_time) > moment());
    if (currentMove) return getFormattedCombinedStatusFromMove(currentMove);
    return null;
  };

  /** Get formatted status string from a hangtag */
  const getFormattedStatusFromHangtag = (hangtag = null) => {
    // Default fallback status string when not enough info is provided
    let fallbackStatus = `Unknown`;

    // Check for hangtag
    if (hangtag) {
      // Set local variables
      fallbackStatus = `Unassigned`;
      const status = hangtag.status ? hangtag.status.toLowerCase() : null;
      const type = hangtag.type ? hangtag.type.toLowerCase() : null;

      // Check statuses and return a formatted string
      if (status && type === `concierge`) {
        if (status === `unassigned`) return `Unassigned`;
        if (status === `assigned`) return `Assigned`;
        if (status === `scanned`) return `Scanned`;
        if (status === `completed`) return `Completed`;
        return capEach(status);
      }
      if (status && type === `yard`) {
        if (status === `unassigned`) return `Unassigned`;
        if (status === `scanned`) return `Started`;
        if (status === `assigned`) return `Assigned`;
        if (status === `completed`) return `Staged`;
        return capEach(status);
      }
    }
    return fallbackStatus;
  };

  /** Get formatted vehicle string from the move object */
  const getFormattedVehicleFromMove = (move = null, includeYear = true, includeColor = true) => {
    // Default fallback vehicle string when not enough info is provided
    const fallbackVehicle = `Vehicle`;

    // Check for move
    if (move) {
      // Set a vehicle type
      let type = ``;
      if (move.consumer_type === `customer`) type = `Customer `;
      if (move.consumer_type === `loaner`) type = `Loaner `;

      // Set vehicle pieces
      const year = move.vehicle_year || move.driver_vehicle_year || null;
      const make = move.vehicle_make || move.driver_vehicle_make || null;
      const model = move.vehicle_model || move.driver_vehicle_model || null;
      const color = move.vehicle_color || move.driver_vehicle_color || null;

      // Set local vehicle string
      let vehicle = ``;

      // Check for make and model
      if (make && model) vehicle = `${make} ${model}`;
      else if (make) vehicle = make;
      else if (model) vehicle = model;
      else vehicle = type + fallbackVehicle;

      // Include color and year if specified
      if (includeYear && year) vehicle = `${year} ${vehicle}`;
      if (includeColor && color) vehicle = `${vehicle} (${capFirst(color)})`;

      // Return the built vehicle string
      return vehicle;
    }
    return fallbackVehicle;
  };

  /** Check if the move is in-progress */
  const checkMoveInProgress = (move, includeCompleted = false) => {
    if (move) {
      const moveType = move.move_type || ``;
      const status = move.status || ``;

      if (moveType === `drive`) {
        if ((status.includes(`pickup`) || status.includes(`delivery`)) && status !== `delivery successful`) return true;
      }

      if (moveType === `ride`) {
        if (status === `accepted` || status === `arrived` || status === `pickedUp`) return true;
      }

      if (includeCompleted) return checkMoveCompleted(move);
    }
    return false;
  };

  /** Check if the move was completed */
  const checkMoveCompleted = move => {
    if (move) {
      const moveType = move.move_type || ``;
      const status = move.status || ``;
      if (moveType === `drive` && status === `delivery successful`) return true;
      if (moveType === `ride` && status === `droppedOff`) return true;
    }
    return false;
  };

  /** Get the latest timestamp from a move -  */
  const getLatestTimestampFromMove = (move = null) => {
    if (move) {
      if (move.delivery_successful) return move.delivery_successful;
      if (move.delivery_arrived) return move.delivery_arrived;
      if (move.delivery_started) return move.delivery_started;
      if (move.pickup_successful) return move.pickup_successful;
      if (move.pickup_arrived) return move.pickup_arrived;
      if (move.pickup_started) return move.pickup_started;
    }
    return null;
  };

  /** Get driver initials string from the move object */
  const getDriverInitialsFromMove = (move = null) => {
    // Default fallback initials string when not enough info is provided
    const fallbackInitials = `N/A`;

    // Check for move
    if (move) {
      // Set local variables
      const fullName = move.driver_name ? move.driver_name : fallbackInitials;

      // Get/Set initials
      if (fullName !== fallbackInitials) {
        // Split the full name and allow up to 3 initials
        const names = fullName.split(` `);
        const firstI = names.length > 0 ? names[0][0] : ``;
        const secondI = names.length > 1 ? names[1][0] : ``;
        const thirdI = names.length > 2 ? names[2][0] : ``;

        const initials = `${firstI}${secondI}${thirdI}`.toUpperCase();

        // Return built initials
        if (initials && initials !== ``) return initials;
      }
    }
    return fallbackInitials;
  };

  /** Get the general pickup or delivery time from the move to display to the user */
  const getPickupOrDeliveryTimeFromMove = (type = `pickup`, move = null, format = `MM/DD/YYYY hh:mm A`) => {
    if (move) {
      if (type === `pickup`) {
        if (move.pickup_arrived) return moment(move.pickup_arrived).format(format);
        if (move.pickup_time) return moment(move.pickup_time).format(format);
        if (move.ready_by) return moment(move.ready_by).format(format);
      }
      if (type === `delivery`) {
        if (move.delivery_successful) return moment(move.delivery_successful).format(format);
        if (move.delivery_time) return moment(move.delivery_time).format(format);
        if (move.deliver_by) return moment(move.deliver_by).format(format);
      }
    }
    return null;
  };

  /** Get formatted type string from the location object */
  const getFormattedTypeFromLocation = (location = null) => {
    // Default fallback status string when not enough info is provided
    const fallbackStatus = `-`;

    // Check for location
    if (location) {
      // Set local variables
      const type = location.type || null;

      // Check type and return a formatted string
      if (type && type !== ``) {
        if (type === `customer`) return `Standard`;
        else if (type === `consumer business`) return `Consumer Business`;
        else if (type === `consumer residential`) return `Consumer Residential`;
        else return capEach(type);
      }
    }
    return fallbackStatus;
  };

  /** Get a location from a list by passing in the id and list to look in */
  const getLocationByIdFromList = (locationId, locations) => {
    if (locationId) {
      const foundLocation = locations.find(pl => pl.id === locationId);
      if (foundLocation) return foundLocation;
    }
    return null;
  };

  /** Get the accessorial in pending status by passing in the accessorials array */
  const getPendingAccessorial = accs => {
    return accs.find(acc => acc.status === 'pending' && acc.authorization);
  };

  /** Get preferred name of location */
  const getNameFromLocation = (location = null) => {
    const fallbackName = `Unknown Location`;

    if (location) {
      let locationName = fallbackName;

      if (location.name) locationName = location.name;
      // if (location.nickname) locationName = location.nickname;

      return locationName;
    }

    return fallbackName;
  };

  /** Get drive type from move */
  const getDriveTypeFromMove = (move = null) => {
    const fallbackType = `ops`;

    if (move) {
      let driveType = fallbackType;

      if (move.consumer_pickup && move.consumer_type === `customer`) driveType = `concierge`;
      if (move.consumer_type === `loaner`) driveType = `loaner`;

      return driveType;
    }

    return fallbackType;
  };

  /** Find the duration between two times (in seconds) */
  const durationBetween = (timeOne = null, timeTwo = null) => {
    if (timeOne && timeTwo) {
      const formattedTimeOne = moment(timeOne).format();
      const formattedTimeTwo = moment(timeTwo);
      const durationSec = formattedTimeTwo.diff(formattedTimeOne, `second`);
      return durationSec;
    }
    return 0;
  };

  /** Get duration (in minutes) from specified lane */
  const getDurationInMinutes = (seconds = 0, precision = 0) => {
    const fallbackDuration = `0 min`;

    if (seconds) {
      return `${Number(seconds / 60).toFixed(precision)} min`;
    }

    return fallbackDuration;
  };

  /** Get the number of minutes between 2 timestamps */
  const getMinutesBetween = (startTimestamp = null, endTimestamp = null) => {
    if (startTimestamp && endTimestamp) {
      let start = moment(startTimestamp);
      let end = moment(endTimestamp);
      if (start && end) {
        let dur = end.diff(start);
        let mins = Math.round(Math.abs(moment.duration(dur).asMinutes()));
        return mins;
      }
    }
    return 0;
  };

  /** Get a monetary value from a number or string number */
  const getMonetaryValue = (amount = null, returnNull = false) => {
    if (amount) {
      const value = Number(amount) || null;
      if (value) return `$` + value.toFixed(2);
    }
    if (returnNull) return null;
    return `$0.00`;
  };

  /** Get the last N digits of a string (N defautls to 8) */
  const getLastNDigits = (str = null, n = 8) => {
    if (str && typeof str === `string` && str.length > n) return str.slice(-n);
    return str;
  };

  /** Get a cleansed text string (remove underscores and cap all) */
  const getReadableText = (str = null, nbsp = false) => {
    if (str) {
      let newStrArray = str.split(/[.\s_-]/g);
      newStrArray = newStrArray.map(val => capFirst(val).trim());
      return newStrArray.join(nbsp ? `\xa0` : ` `);
    }
    return str;
  };

  /** Get snake case from a string (underscore separators + lowercase) */
  const getSnakeCase = (str = null, upperCase = false) => {
    if (str) {
      let newStrArray = str.split(/[,.\s-]/g);
      newStrArray = newStrArray.map(val => val.trim());
      newStrArray = newStrArray.join(`_`);
      return upperCase ? newStrArray.toUpperCase() : newStrArray.toLowerCase();
    }
    return str;
  };

  /** Get a cleansed monetary value from a number or string */
  const getCleansedMonetaryValue = (num = null) => {
    if (num) {
      let value = Number(num).toFixed(2);
      return `$${value}`;
    }
    return num;
  };

  /** Get a cleansed phone number from a string */
  const getCleansedPhoneNumber = (str = null) => {
    if (str) {
      let cleaned = ('' + str).replace(/\D/g, '');
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ')\xa0', match[3], '-\u2060', match[4]].join('');
      }
    }
    return str;
  };

  /** Replace spaces with non-breaking spaces */
  const getNonBreakingWord = (str = null) => {
    if (str) {
      let newStr = str.replace(/ /g, '\xa0');
      return newStr;
    }
    return str;
  };

  /** Generate a list of private step ids from a workflowset */
  const getPrivateStepIds = (workflowset = null) => {
    /** Recursively find and build an array of private workflow steps */
    const findPrivateSteps = (privateSteps, stepsToSearch) => {
      if (!stepsToSearch || !stepsToSearch.length) return privateSteps;
      for (let i = 0; i < stepsToSearch.length; i++) {
        const currentStep = stepsToSearch[i];
        if (currentStep.config && currentStep.config.private) privateSteps.push(currentStep);
        if (currentStep.steps && currentStep.steps.length) {
          privateSteps = findPrivateSteps(privateSteps, currentStep.steps);
        }
      }
      return privateSteps;
    };

    // If we don't have a workflowset, return an empty array
    if (!workflowset) return [];

    // Initialize an array to hold private steps
    let privateSteps = [];

    // Dig through pickup workflow
    if (workflowset.pickupWorkflow && workflowset.pickupWorkflow.steps && workflowset.pickupWorkflow.steps.length) {
      privateSteps = findPrivateSteps(privateSteps, workflowset.pickupWorkflow.steps);
    }

    // Dig through delivery workflow
    if (
      workflowset.deliveryWorkflow &&
      workflowset.deliveryWorkflow.steps &&
      workflowset.deliveryWorkflow.steps.length
    ) {
      privateSteps = findPrivateSteps(privateSteps, workflowset.deliveryWorkflow.steps);
    }

    // Dig through fuel workflow
    if (workflowset.fuelWorkflow && workflowset.fuelWorkflow.steps && workflowset.fuelWorkflow.steps.length) {
      privateSteps = findPrivateSteps(privateSteps, workflowset.fuelWorkflow.steps);
    }

    // Once we have all the private steps, return an array of their ids
    return privateSteps.map(step => step.id);
  };

  /** Parse move workflow data */
  const getWorkflowData = (type, workflowData, format = `csv`, keyValue = false, workflowset = null) => {
    // Set the type string (pickup, delivery or extra)
    const typeStr = type ? `${type}_` : `extra_`;

    // Check for workflow data and parse it
    if (workflowData && typeof workflowData === `object`) {
      // Initialize editable workflow data
      let editedWorkflowData = { ...workflowData };

      // Remove private steps from workflow data
      if (workflowset) {
        const privateStepIds = getPrivateStepIds(workflowset);
        if (privateStepIds && privateStepIds.length) {
          Object.keys(editedWorkflowData).forEach((id, i) => {
            if (privateStepIds.includes(id)) {
              delete editedWorkflowData[id];
            }
          });
        }
      }

      // Split out the workflow data keys and values
      const workflowDataKeys = editedWorkflowData ? Object.keys(editedWorkflowData) : [];
      const workflowDataVals = workflowDataKeys.map(key => editedWorkflowData[key]) || [];

      // Check for keyValue bool and return the data in the specified format
      if (keyValue) {
        const workflowData = workflowDataKeys.map((key, i) => {
          let formattedKey = key;
          if (format === `csv`) formattedKey = typeStr + getSnakeCase(key);
          if (format === `move_details`) formattedKey = getReadableText(key, true);
          return { index: i, key: formattedKey, val: workflowDataVals[i] };
        });

        return workflowData || [];
      } else {
        let workflowData = {};
        workflowDataKeys.forEach((key, i) => {
          let formattedKey = key;
          if (format === `csv`) formattedKey = typeStr + getSnakeCase(key);
          if (format === `move_details`) formattedKey = getReadableText(key, true);
          workflowData[formattedKey] = workflowDataVals[i];
        });

        return workflowData || {};
      }
    }
    return null;
  };

  /** Get the cancel reason from the move */
  const getCancelHeaderFromMove = (move = null) => {
    const cancelStatus = move && move.cancel_status ? move.cancel_status : null;

    if (cancelStatus === `pending` || cancelStatus === `seen`) return `CANCEL REQUESTED`;
    return `CANCELED`;
  };

  /** Get the cancel reason from the move */
  const getCancelReasonFromMove = (move = null) => {
    const cancelStatus = move && move.cancel_status ? move.cancel_status : null;
    const cancelReason = move && move.cancel_reason ? move.cancel_reason : null;

    if (cancelReason) return `${cancelReason}.`;
    if (cancelStatus === `pending` || cancelStatus === `seen`) return `Cancel requested by user.`;
    if (cancelStatus === `delivered`) return `Canceled after vehicle was delivered.`;
    return `Cancel reason not provided.`;
  };

  /** Check a number to see if its negative, if so, clamp it to 0 */
  const clampNegNum = (num = null) => {
    if (num && !isNaN(parseFloat(num)) && num > 0) return num;
    return 0;
  };

  /** Copy a string to the clipboard */
  const copyToClipboard = (str = null) => {
    try {
      if (str) {
        navigator.clipboard.writeText(str);
        toast.info(`Copied Text - '${str}'`, { autoClose: 2000 });
      } else toast.warning(`No text was found to copy!`);
    } catch (err) {
      toast.error(`Failed to copy text!`);
      console.error(`Failed to copy text:`, err);
    }
  };

  const circularJSONStringify = (json, spaces = 2, hiddenProperties = []) => {
    let cache = [];
    let formattedJson = '';
    try {
      formattedJson = JSON.stringify(
        json,
        (key, value) => {
          if (typeof value === 'object' && value !== null) {
            // Duplicate reference found, discard key
            if (cache.includes(value)) return;

            // Store value in our collection
            cache.push(value);
          }
          return hiddenProperties.includes(key) ? 'hidden by circularStringify()' : value;
        },
        spaces
      );
    } catch (error) {
      console.error(`Failed doing JSON.stringify() on circular object: ${error.message}`, json, error);
    } finally {
      cache = null;
      return formattedJson;
    }
  };

  /** Get the driver's first name from the display_name */
  const getFirstNameFromDisplayName = (displayName = null) => {
    if (displayName) {
      const splitArr = displayName.split(` `);
      if (splitArr.length) {
        const firstName = splitArr[0];
        return firstName;
      }
    }
    return null;
  };

  /** Get the driver's middle name from the display_name */
  const getMiddleNameFromDisplayName = (displayName = null) => {
    if (displayName) {
      const splitArr = displayName.split(` `);
      if (splitArr.length > 2) {
        const middleName = splitArr[1];
        return middleName;
      }
    }
    return null;
  };

  /** Get the driver's last name from the display_name */
  const getLastNameFromDisplayName = (displayName = null) => {
    if (displayName) {
      const splitArr = displayName.split(` `);
      if (splitArr.length > 1) {
        const lastName = splitArr[splitArr.length - 1];
        return lastName;
      }
    }
    return null;
  };

  const getUserName = (obj = null) => {
    if (obj) {
      if (getPropValue(obj, `name`)) return obj.name;
      if (getPropValue(obj, `driver_name`)) return obj.driver_name;
      if (getPropValue(obj, `display_name`)) return obj.display_name;
      if (getPropValue(obj, `driver.name`)) return obj.driver.name;
      if (getPropValue(obj, `driver.driver_name`)) return obj.driver.driver_name;
      if (getPropValue(obj, `driver.display_name`)) return obj.driver.display_name;
      if (getPropValue(obj, `user.name`)) return obj.user.name;
      if (getPropValue(obj, `user_name`)) return obj.user_name;
      if (getPropValue(obj, `user.driver_name`)) return obj.user.driver_name;
      if (getPropValue(obj, `user.display_name`)) return obj.user.display_name;
      if (getPropValue(obj, `driver.user.name`)) return obj.driver.user.name;
      if (getPropValue(obj, `driver.user.driver_name`)) return obj.driver.user.driver_name;
      if (getPropValue(obj, `driver.user.display_name`)) return obj.driver.user.display_name;
    }
    return null;
  };

  const getInitialsFromName = (obj = null) => {
    // Default fallback initials string when not enough info is provided
    const fallbackInitials = `N/A`;

    // Check for obj
    if (obj) {
      // Set local variables
      const fullName = getUserName(obj) || fallbackInitials;

      // Get/Set initials
      if (fullName !== fallbackInitials) {
        // Split the full name into first, middle and last
        const firstName = getFirstNameFromDisplayName(fullName);
        const middleName = getMiddleNameFromDisplayName(fullName);
        const lastName = getLastNameFromDisplayName(fullName);

        // Assign each initial
        const firstI = firstName ? firstName[0] : ``;
        const middleI = middleName ? middleName[0] : ``;
        const lastI = lastName ? lastName[0] : ``;

        // Return built initials
        const initials = `${firstI}${middleI}${lastI}`.toUpperCase();
        if (initials && initials !== ``) return initials;
      }
    }
    return fallbackInitials;
  };

  // Return hook logic
  return {
    goToRoute,
    goToPreviousPage,
    goToNextPage,
    goToMovesIndex,
    goToLanesIndex,
    goToLocationsIndex,
    goToMovePlanner,
    goToMoveDetails,
    goToInvoice,
    goToUserDetails,
    condensedCase,
    capFirst,
    capEach,
    capAll,
    round,
    checkNeg,
    getFormattedRidesharePartner,
    getFormattedCombinedStatusFromMove,
    getFormattedStatusFromDrive,
    getFormattedCancelStatusFromDrive,
    getFormattedStatusFromRide,
    getFormattedStatusFromPlan,
    getFormattedStatusFromHangtag,
    getFormattedVehicleFromMove,
    getInitialsFromName,
    checkMoveInProgress,
    checkMoveCompleted,
    getLatestTimestampFromMove,
    getDriverInitialsFromMove,
    getPickupOrDeliveryTimeFromMove,
    getFormattedTypeFromLocation,
    getLocationByIdFromList,
    getPendingAccessorial,
    getNameFromLocation,
    getDriveTypeFromMove,
    durationBetween,
    getDurationInMinutes,
    getMinutesBetween,
    getMonetaryValue,
    getLastNDigits,
    getReadableText,
    getSnakeCase,
    getCleansedMonetaryValue,
    getCleansedPhoneNumber,
    getNonBreakingWord,
    getPrivateStepIds,
    getWorkflowData,
    getCancelHeaderFromMove,
    getCancelReasonFromMove,
    clampNegNum,
    copyToClipboard,
    circularJSONStringify,
  };
}
