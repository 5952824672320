import React from 'react';
import { makeStyles } from '@material-ui/core';

import * as Sentry from '@sentry/react';
import { GET_USERS, GET_CUSTOMERS, GET_USERS_BY_ALLOWED_CUSTOMER, GET_ALL_DEALERS } from './gql';
import DefaultErrorFallback from '../../reusable/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../reusable/Fallbacks/DefaultEmptyFallback';
import Toolbar from '../../reusable/Toolbar';
import { useData } from '../../../DataProvider';
import { getUserRole, getCustomerId } from '../../utils/authHelper';

import { UsersFilter, UsersTable } from './index';

//////////////////////// COMPONENT ////////////////////////
function Users(props) {
  const cls = useStyles();
  const ctx = useData();

  const [search, setSearch] = React.useState(null);
  const [defaultRole, setDefaultRole] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [customerId, setCustomerId] = React.useState(null);

  const fetchUserRole = async () => {
    let role = await getUserRole();
    if (role) setDefaultRole(role);
  };

  const fetchCustomerId = async () => {
    let id = await getCustomerId();
    if (id) setCustomerId(id);
  };

  //Admins can view all dealer users
  const getAllDealers = async () => {
    const allDealers = await ctx.apolloClient.query({ query: GET_ALL_DEALERS });
    if (allDealers && allDealers.data && allDealers.data.users && allDealers.data.users.length > 0) {
      setUsers(allDealers.data.users);
    }
  };

  //Dealer users can view other users in their organization
  const buildUsersList = async () => {
    let customersList = [];
    const customersByOrg = await ctx.apolloClient.query({
      query: GET_CUSTOMERS,
      variables: { customerId: customerId },
    });
    if (
      customersByOrg &&
      customersByOrg.data &&
      customersByOrg.data.customers &&
      customersByOrg.data.customers.length > 0 &&
      customersByOrg.data.customers[0].organization &&
      customersByOrg.data.customers[0].organization.customers &&
      customersByOrg.data.customers[0].organization.customers.length > 0
    ) {
      customersByOrg.data.customers[0].organization.customers.map(c => {
        customersList.push(c.id);
      });
    }

    let usersList = [];
    if (customersList && customersList.length > 0) {
      const usersByDefaultCustomer = await ctx.apolloClient.query({
        query: GET_USERS,
        variables: { customerIds: customersList },
      });
      if (
        usersByDefaultCustomer &&
        usersByDefaultCustomer.data &&
        usersByDefaultCustomer.data.users &&
        usersByDefaultCustomer.data.users.length > 0
      ) {
        usersList = usersByDefaultCustomer.data.users.filter(u => u.default_role && u.default_role.includes('dealer'));
      }
    }

    if (customersList && customersList.length > 0) {
      const usersByAllowedCustomer = await ctx.apolloClient.query({
        query: GET_USERS_BY_ALLOWED_CUSTOMER,
        variables: { customerIds: customersList },
      });
      if (
        usersByAllowedCustomer &&
        usersByAllowedCustomer.data &&
        usersByAllowedCustomer.data.usertocustomers &&
        usersByAllowedCustomer.data.usertocustomers.length > 0
      ) {
        if (usersList && usersList.length > 0) {
          usersByAllowedCustomer.data.usertocustomers.map(u => {
            let user = usersList.find(user => u && u.user && u.user.id && user && user.id === u.user.id);
            if (!user) usersList.push(u.user);
          });
          usersList = usersList.filter(u => u && u.default_role && u.default_role.includes('dealer'));
        } else {
          usersList = usersByAllowedCustomer.data.usertocustomers.filter(u => u && u.user);
        }
      }
    }
    setUsers(usersList);
  };

  React.useEffect(() => {
    fetchCustomerId();
    fetchUserRole();
  }, []);

  React.useEffect(() => {
    if (customerId && defaultRole && defaultRole.includes('dealer')) {
      buildUsersList();
    } else if (defaultRole && defaultRole === 'admin') {
      getAllDealers();
    }
  }, [customerId, defaultRole]);

  if (users && users.length > 0 && defaultRole && defaultRole.includes('admin')) {
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Users'>
          <UsersFilter search={search} onSearchChange={setSearch} />
        </Toolbar>
        <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING USERS TABLE' />}>
          <UsersTable users={users} search={search} />
        </Sentry.ErrorBoundary>
      </div>
    );
  } else if ((!users || !users.length > 0) && defaultRole && defaultRole.includes('admin')) {
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Users'>
          <UsersFilter />
        </Toolbar>
        <DefaultEmptyFallback message='COULD NOT FIND USERS' />
      </div>
    );
  } else {
    return (
      <div className={cls.root}>
        <DefaultEmptyFallback message='RESTRICTED ACCESS' />
      </div>
    )
  }
}

//////////////////////// STYLES ////////////////////////
const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(7),
  },
}));
//////////////////////// EXPORT ////////////////////////
export default Users;
