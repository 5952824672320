import React from 'react';
import { toast } from 'react-toastify';
import { makeStyles, Typography, LinearProgress } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../reusable/Modal';

import { useUserDetails } from './UserDetailsProvider';

//////////////////////// COMPONENT ////////////////////////

export default function TerminationModalContent({ input, onClose }) {
  const cls = useStyles();
  const ctx = useUserDetails();

  const [isSaving, setIsSaving] = React.useState(false);

  // Handle submitting the form
  const handleSubmit = async () => {
    const res = await ctx.terminateUser(input.user.id);
    if (res) {
      toast.success(`Successfully terminated user!`, { autoClose: 2500 });
      if (onClose) onClose();
    } else {
      toast.error(`Failed to terminate user, please contact an admin!`);
    }

    setIsSaving(false);
  };

  return (
    <>
      <ModalHeader onClose={onClose}>Terminate User</ModalHeader>

      <ModalContent>
        <Typography className={cls.subtitle}>
          By clicking "submit" below, the user's login credentials will be revoked, and they will no longer be able to access our portals. 
          IMPORTANT: If this user is also a driver, be sure to follow the termination steps from their Driver Details page after submitting.
          Please follow up with anyone on a need-to-know basis, especially the user.
        </Typography>

        <Spacer />
         
      </ModalContent>

      <div className={cls.background}>{isSaving ? <LinearProgress /> : null}</div>

      <ModalFooter>
        <ModalAction
          loading={isSaving}
          disabled={isSaving}
          color='primary'
          onClick={() => {
            setIsSaving(true);
            handleSubmit();
          }}
        >
          Submit
        </ModalAction>

        <ModalAction onClick={() => onClose()}>Close</ModalAction>
      </ModalFooter>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  subtitle: {
    lineHeight: 1.333,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
}));