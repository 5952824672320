import React from 'react';
import { toast } from 'react-toastify';
import { makeStyles, Container } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useData } from '../../../DataProvider';
import { REFRESH_CLAIMS } from './gql';

import Toolbar from '../../reusable/Toolbar';

import UserDetailsForm from './UserDetailsForm';
import UserToolbar from './UserToolbar';
import TerminationModal from './TerminationModal';
import ReinstateModal from './ReinstateModal';

//////////////////////// COMPONENT ////////////////////////

export default function UserDetails({user, refetch}) {
  const cls = useStyles();
  const { apolloClient} = useData()

  const [terminationModal, setTerminationModal] = React.useState({ open: false, input: null, output: null });
  const handleTerminationModalOpen = (input = null) => {
    setTerminationModal({ open: true, input: input, output: null });
  };
  const handleTerminationModalClose = async (output = null) => {
    setTerminationModal({ open: false, input: null, output: output });
  };

  const [reinstateModal, setReinstateModal] = React.useState({ open: false, input: null, output: null });
  const handleReinstateModalOpen = (input = null) => {
    setReinstateModal({ open: true, input: input, output: null });
  };
  const handleReinstateModalClose = async (output = null) => {
    setReinstateModal({ open: false, input: null, output: output });
  };

  const refreshUserClaims = async () => {
    const refreshedClaimsRes = await apolloClient.mutate({mutation: REFRESH_CLAIMS, variables: {userId: user.id }})
  }

  // Handle refetch callback to pass as props
  const handleRefetch = () => {
    refetch();
  };

  const resetPassword = async email => {
    try {
      await sendPasswordResetEmail(email);
      toast.success('Password reset email sent successfully');
    } catch (err) {
      console.log('Error sending reset password email:', err);
      toast.error('Error sending password reset email');
    }
  };

  const refreshClaims = async () => {
    try {
      const refreshedClaimsRes = await refreshUserClaims()
      if (refreshedClaimsRes && refreshedClaimsRes.data && refreshedClaimsRes.data.update_users && refreshedClaimsRes.data.update_users.affected_rows > 0){
        toast.success('Successfully refreshed claims');
      } else toast.error('Error refreshing claims')
    } catch (err) {
      console.log('Error refreshing claims:', err);
      toast.error('Error refreshing claims');
    }
  };

  const userActions = [
    {
      label: `Reset Password`,
      handler: async () => {
        const resetSuccess = await resetPassword(user.email);
        if (resetSuccess) {
          toast.success(`Password reset email sent successfully`);
        } else toast.error(`Failed to send password reset email`);
      },
    },
    {
      label: `Refresh Auth Claims`,
      handler: async () => {
        await refreshClaims();
      },
    },
    {
      label: `Deactivate User`,
      handler: () => handleTerminationModalOpen({ user, refetch: handleRefetch }),
      disabled: !user.active,
    },
    {
      label: `Reinstate User`,
      handler: () => handleReinstateModalOpen({ user, refetch: handleRefetch }),
      disabled: user.active,
    },
  ];

  return (
    <>
      <TerminationModal
        open={terminationModal.open}
        input={terminationModal.input}
        onClose={handleTerminationModalClose}
      />

      <ReinstateModal
        open={reinstateModal.open}
        input={reinstateModal.input}
        onClose={handleReinstateModalClose}
      />

      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='User Details' refetch={handleRefetch} actions={userActions}>
            <UserToolbar user={user} />
          </Toolbar>

          <Spacer />

          {/* USER BASE INFO */}
          <UserDetailsForm user={user} />

          <Spacer />
        </Container>
      </div>
      </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));