import React from 'react';
import { toast } from 'react-toastify';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { useTools } from '../../hooks/useTools';
import { getUserEmail, getCustomerId } from '../../utils/authHelper';

import {
  GET_ALLOWED_CUSTOMERS,
  GET_ALLOWED_PAYER_PAYEES,
  GET_ROLETYPES,
  GET_ALLOWED_ROLES,
  UPSERT_ALLOWED_CUSTOMERS,
  UPSERT_ALLOWED_ROLES,
  SAVE_USER_DETAILS,
  TERMINATE_USER,
  REINSTATE_USER,
  CREATE_USER,
  REMOVE_ALLOWED_CUSTOMER,
  REMOVE_ALLOWED_ROLE,
  GET_ORG_CUSTOMERS
} from './gql';
import { useData } from '../../../DataProvider';

const log = false;

const UserDetailsContext = React.createContext({});

function UserDetailsProvider({ children, user = {}, handleRefetch }) {
  const { apolloClient } = useData();
  const { goToRoute } = useTools();

  // Base State
  const [editMode, setEditMode] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [adminEmail, setAdminEmail] = React.useState(null);
  const [adminCustomerId, setAdminCustomerId] = React.useState(null);

  // User Fields
  const [active, setActive] = React.useState();
  const [displayName, setDisplayName] = React.useState();
  const [email, setEmail] = React.useState();
  const [role, setRole] = React.useState();
  const [avatarUrl, setAvatarUrl] = React.useState();
  const [updatedBy, setUpdatedBy] = React.useState();
  const [phone, setPhone] = React.useState();
  const [userId, setUserId] = React.useState();

  // For building Hasura claims
  const [roles, setRoles] = React.useState();
  const [allowedRoles, setAllowedRoles] = React.useState();
  const [allowedRoleNames, setAllowedRoleNames] = React.useState();
  const [customers, setCustomers] = React.useState();
  const [allowedCustomers, setAllowedCustomers] = React.useState();
  const [customerId, setCustomerId] = React.useState();
  const [payers, setPayers] = React.useState();
  const [payees, setPayees] = React.useState();
  const [originalRoleIds, setOriginalRoleIds] = React.useState();
  const [originalCustomerIds, setOriginalCustomerIds] = React.useState();
  const [allowedRoleIds, setAllowedRoleIds] = React.useState();
  const [allowedCustomerIds, setAllowedCustomerIds] = React.useState([]);
  const [validationErrors, setValidationErrors] = React.useState({})

  //Get the email of the current logged in user who's doing the editing, which will eventually be used to populate the created_by/updated_by fields.
  //Also get the base lists of customers, and roles to be used as selection options
  React.useEffect(() => {
    console.log('customers', customers, customerId)
    let loggedInUserEmail = getUserEmail();
    setAdminEmail(loggedInUserEmail);

    if (!adminCustomerId) {
      fetchCustomerId();
    }

    if ((!customers || !customers.length) && customerId) {
      fetchCustomers();
    }

    if (!roles || !roles.length) {
      fetchRoles();
    }


  }, [customers, roles, adminCustomerId]);

  const fetchCustomerId = async () => {
    const custId = await getCustomerId();
    setAdminCustomerId(custId);
  }


  const fetchRoles = async () => {
    console.log('fetchRoles')
    const res = await apolloClient.query({ query: GET_ROLETYPES });
    const newRoletypes = getPropValue(res, `data.roletypes`);
    if (newRoletypes && newRoletypes.length) {
      setRoles(newRoletypes);
    }
  };

  const fetchCustomers = async () => {
    const res = await apolloClient.query({ query: GET_ORG_CUSTOMERS, variables: { customerId } });
    const newCustomers = res && res.data && res.data.organizations && res.data.organizations.length && res.data.organizations[0].customers && res.data.organizations[0].customers.length ? res.data.organizations[0].customers : [];
    if (newCustomers && newCustomers.length) {
      setCustomers(newCustomers);
    }
  };

  // Set state on user upon receiving prop
  React.useEffect(() => {
    if (user) {
      setActive(user.active || false);
      setDisplayName(user.display_name || null);
      setEmail(user.email || null);
      setRole(user.default_role || null);
      setAvatarUrl(user.avatar_url || null);
      setPhone(user.phone || null);
      setUserId(user.id || null);
      setCustomerId(user.customer_id || null);
    }
    if (user.id) {
      fetchAllowedRoles(user.id);
    }
  }, [user]);

  //Once we have the user's allowed role objects, separate out the name strings.
  //This will make it easier to use conditional endering in the UI components.
  React.useEffect(() => {
    let roleNameArray = [];
    if (allowedRoles && allowedRoles.length > 0) {
      allowedRoles.forEach(r => {
        if (r.roletype && r.roletype.name) {
          roleNameArray.push(r.roletype.name);
        } else if (r.name) {
          roleNameArray.push(r.name);
        }
      });
    }
    setAllowedRoleNames(roleNameArray);
  }, [allowedRoles, userId]);

  //Look up the permissions that are relevant for each allowed role
  React.useEffect(() => {

    if (userId) {
      fetchAllowedCustomers(userId);
    }

    if (customerId) {
      fetchAllowedPayees(customerId);
      fetchAllowedPayers(customerId);
    }

  }, [allowedRoleNames, userId, customerId]);

  const fetchAllowedRoles = async userId => {
    let originalRoles = [];
    const res = await apolloClient.query({ query: GET_ALLOWED_ROLES, variables: { userId } });
    const userToRoles = getPropValue(res, `data.usertoroles`);
    if (userToRoles && userToRoles.length > 0) {
      setAllowedRoles(userToRoles);
      //Store initial allowed roles so that later we can check if one has been removed and we need to delete it
      userToRoles.forEach(role => {
        originalRoles.push(role.role_id);
      });
      setOriginalRoleIds(originalRoles);
    }
  };

  const fetchAllowedCustomers = async userId => {
    let originalCustomers = [];
    const res = await apolloClient.query({ query: GET_ALLOWED_CUSTOMERS, variables: { userId } });
    const userToCustomers = getPropValue(res, `data.usertocustomers`);
    //Store initial allowed customers so that later we can check if one has been removed and we need to delete it
    if (userToCustomers && userToCustomers.length > 0) {
      setAllowedCustomers(userToCustomers);
      userToCustomers.forEach(customer => {
        originalCustomers.push(customer.customer_id);
      });
      setOriginalCustomerIds(originalCustomers);
    }
  };

  const fetchAllowedPayees = async customerId => {
    let allowedPayees = [];
    const res = await apolloClient.query({ query: GET_ALLOWED_PAYER_PAYEES, variables: { customerId } });
    const payerToCustomer = getPropValue(res, `data.payertocustomer`);
    if (payerToCustomer && payerToCustomer.length > 0) {
      payerToCustomer.forEach(p => {
        if (p.payer_id === customerId) {
          allowedPayees.push(p);
        }
      });
    }
    setPayees(allowedPayees);
  };

  const fetchAllowedPayers = async customerId => {
    let allowedPayers = [];
    const res = await apolloClient.query({ query: GET_ALLOWED_PAYER_PAYEES, variables: { customerId } });
    const payerToCustomer = getPropValue(res, `data.payertocustomer`);
    if (payerToCustomer && payerToCustomer.length > 0) {
      payerToCustomer.forEach(p => {
        if (p.payee_id === customerId) {
          allowedPayers.push(p);
        }
      });
    }
    setPayers(allowedPayers);
  };

  //Determine if any allowances were removed during the editing session
  const findRecordsToRemove = (original, current) => {
    let missingIds = [];
    original.forEach(o => {
      if (current && current.length > 0 && !current.includes(o)) {
        missingIds.push(o);
      }
    });
    return missingIds;
  };

  const checkIsFormValid = () => {
    let isValid = true;
    let newValidationErrors = {};

    const isValidEmail = email => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
      };
    
      const isValidUrl = url => {
        const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlPattern.test(url);
      };

      const isValidPhoneNumber = phoneNumber => {
        const digitsOnly = phoneNumber.replace(/\D/g, '');
        const phonePattern = /^\d{10}$/;
  
        return phonePattern.test(digitsOnly);
      };

    if (!email || !isValidEmail(email)) {
        isValid = false;
        newValidationErrors.email = `A valid email is required!`;
    }

    if (avatarUrl && !isValidUrl(avatarUrl)) {
        isValid = false;
        newValidationErrors.avatar = `Avatar URL is invalid!`;
    }

    if (phone && !isValidPhoneNumber(phone)) {
        isValid = false;
        newValidationErrors.phone = `Phone number is invalid!`;
    }

    if (!role) {
      isValid = false;
      newValidationErrors.role = `A user role is required!`;
    }
    if (!customerId) {
      isValid = false;
      newValidationErrors.customerId = `A default customer is required!`;
    }
    if (role && role === 'dealer-admin' && allowedCustomers && allowedCustomers.length <= 1) {
      isValid = false;
      newValidationErrors.allowedCustomers = `Dealer admin users require more than one allowed customer!`;
    }

    setValidationErrors(newValidationErrors);

    return isValid;
  };

  //If the form is valid: 
  // 1) Remove allowances as needed
  // 2) Insert new allowances
  // 3) Update the user record, which additionally will trigger a build of Firebase claims based on the allowances
  const handleSave = async () => {
    setIsSaving(true);

    const formValidation = checkIsFormValid();

    if (!formValidation) {
      if (validationErrors.customers === false) {
        toast.error(`Dealer-admins must have at least one allowed customer.`);
      } else toast.error(`Failed to save. Please check the form for errors.`);
    } else {
      let customersToRemove;
      let rolesToRemove;

      if (
        allowedRoles &&
        allowedRoles.length &&
        allowedRoles.length > 0 &&
        originalRoleIds &&
        originalRoleIds.length > 0
      ) {
        rolesToRemove = findRecordsToRemove(originalRoleIds, allowedRoleIds);
      }

      if (
        allowedCustomers &&
        allowedCustomers.length &&
        allowedCustomers.length > 0 &&
        originalCustomerIds &&
        originalCustomerIds.length > 0
      ) {
        customersToRemove = findRecordsToRemove(originalCustomerIds, allowedCustomerIds);
      }

      try {

        if (rolesToRemove && rolesToRemove.length > 0) {
          for (let i = 0; i < rolesToRemove.length; i++) {
            const res = await apolloClient.mutate({ mutation: REMOVE_ALLOWED_ROLE, variables: { roleId: rolesToRemove[i], userId: userId } });
          }
        }

        if (customersToRemove && customersToRemove.length > 0) {
          for (let i = 0; i < customersToRemove.length; i++) {
            const res = await apolloClient.mutate({
              mutation: REMOVE_ALLOWED_CUSTOMER,
              variables: { customerId: customersToRemove[i], userId: userId },
            });
          }
        }
      } catch (err) {
        console.log('Error removing allowances', err);
      }

      const allowedCustomersObj =
        allowedCustomers &&
        allowedCustomers.length &&
        allowedCustomers.length > 0 &&
        allowedCustomers.map(c => {
          return {
            customer_id: c.customer_id || c.id,
            user_id: user.id,
            updated_at: 'now()',
            updated_by: adminEmail,
            created_at: 'now()',
            created_by: adminEmail,
          };
        });

      const allowedRolesObj =
        allowedRoles &&
        allowedRoles.length &&
        allowedRoles.length > 0 &&
        allowedRoles.map(r => {
          return {
            role_id: r.role_id || r.id,
            user_id: user.id,
            updated_at: 'now()',
            updated_by: adminEmail,
            created_at: 'now()',
            created_by: adminEmail,
          };
        });

      try {
        if (allowedCustomersObj) {
          const res = await apolloClient.mutate({ mutation: UPSERT_ALLOWED_CUSTOMERS, variables: { allowedCustomersObj: allowedCustomersObj } });
        }
        if (allowedRolesObj) {
          const res = await apolloClient.mutate({ mutation: UPSERT_ALLOWED_ROLES, variables: { allowedRolesObj: allowedRolesObj } });
        }
      } catch (err) {
        console.log('Error upserting new allowances:', err);
        toast.error('Failed to update allowances. User permissions could not be rebuilt');
        setIsSaving(false);
      }

      try {
        const saveUserRes = await handleSaveUserChanges(user.id);
        setIsSaving(false);
      } catch (err) {
        console.log('Failed to update user', err);
        setIsSaving(false);
      }
    }
  };

  const handleSaveUserChanges = async (userId) => {
    setIsSaving(true);

    try {
      const variables = {
        userId: userId || null,
        userObj: {
          active: active || true,
          email: email ? email.trim().toLowerCase() : null,
          phone: phone ? phone.trim() : null,
          avatar_url: avatarUrl ? avatarUrl : null,
          default_role: role ? role : null,
          display_name: displayName ? displayName : null,
          updated_at: 'now()',
          customer_id: customerId ? customerId : null,
        },
      };

      const res = await apolloClient.mutate({mutation: SAVE_USER_DETAILS, variables: variables });
      if (res && res.data) {
        if (getPropValue(res, `data.update_users.affected_rows`) > 0) {
          log && console.log(`Successfully updated user record:`, res.data.update_users);
          toast.success(`Successfully updated User!`);
          setEditMode(false);
          if (handleRefetch) handleRefetch();
          return true
        } else {
          toast.error(`Failed to update user!`);
          console.error(`Failed to update user!`);
          return false
        }
      }
    } catch (err) {
      toast.error(`Failed to update user!`);
      console.error(`Failed to update user:`, err);
      return false
    }

    setIsSaving(false);
  };

  const upsertPermissions = async userId => {
      
    const allowedCustomersObj =
      allowedCustomers &&
      allowedCustomers.length &&
      allowedCustomers.length > 0 &&
      allowedCustomers.map(c => {
        return {
          customer_id: c.customer_id || c.id,
          user_id: userId,
          updated_at: 'now()',
          updated_by: adminEmail,
          created_at: 'now()',
          created_by: adminEmail,
        };
      });

    const allowedRolesObj =
      allowedRoles &&
      allowedRoles.length &&
      allowedRoles.length > 0 &&
      allowedRoles.map(r => {
        return {
          role_id: r.role_id || r.id,
          user_id: userId,
          updated_at: 'now()',
          updated_by: adminEmail,
          created_at: 'now()',
          created_by: adminEmail,
        };
      });

    try {
      if (allowedCustomersObj) {
        const res = await apolloClient.mutate({ mutation: UPSERT_ALLOWED_CUSTOMERS, variables: { allowedCustomersObj: allowedCustomersObj } });
      }
      if (allowedRolesObj) {
        const res = await apolloClient.mutate({ mutation: UPSERT_ALLOWED_ROLES, variables: { allowedRolesObj: allowedRolesObj } });
      }
      return true
    } catch (err) {
      console.log('Error upserting new allowances:', err);
      toast.error('Failed to update allowances. User permissions could not be rebuilt');
      return false
    }
  };

  const handleCreateUser = async () => {
    setIsSaving(true);

    const formValidation = checkIsFormValid();

    if (!formValidation) {
      if (validationErrors.customers) {
        toast.error(`Dealer-admins must have at least one allowed customer.`);
      } else toast.error(`Failed to save. Please check the form for errors.`);
    } else {
      try {
        const variables = {
          userObj: {
            active: true,
            email: email ? email.trim() : null,
            phone: phone ? phone.trim() : null,
            avatar_url: avatarUrl ? avatarUrl : null,
            default_role: role ? role : null,
            display_name: displayName ? displayName : null,
            created_at: 'now()',
            updated_at: 'now()',
            customer_id: customerId ? customerId : null,
          },
        };

        const res = await apolloClient.mutate({mutation: CREATE_USER, variables: variables });
        if (res && res.data) {
          if (getPropValue(res, `data.insert_users.affected_rows`) > 0) {
            log && console.log(`Successfully created user record:`, res.data.insert_users);
            const newUsers = getPropValue(res, `data.insert_users.returning`);
            const newUserId = newUsers && newUsers.length && newUsers[0].id ? newUsers[0].id : null;
            log && console.log('NEWUSER', newUsers, newUserId);
            setUserId(newUserId)
            const permissionsRes = await upsertPermissions(newUserId);
            log && console.log('permissionsRes', permissionsRes);
            if (permissionsRes) {
              const updateUserRes = await handleSaveUserChanges(newUserId)
              if (updateUserRes) {
                console.log('updateUserRes', updateUserRes)
                toast.success(`Successfully created User ${newUserId}!`);
                goToRoute('/users');
              }
            }
          } else {
            toast.error(`Failed to create user!`);
            console.error(`Failed to create user! No response from database.`);
          }
        }
      } catch (err) {
        toast.error(`Failed to create user!`);
        console.error(`Failed to create user:`, err);
      }

      setIsSaving(false);
    }
  };

  //Gear menu actions
  const terminateUser = async userId => {
    const res = await apolloClient.mutate({ mutation: TERMINATE_USER, variables: { userId } });
    const terminatedUser = getPropValue(res, `data.update_users.affected_rows`);
    if (terminatedUser) {
      return true;
    } else return false;
  };

  const reinstateUser = async userId => {
    const res = await apolloClient.mutate({ mutation: REINSTATE_USER, variables: { userId } });
    const reinstatedUser = getPropValue(res, `data.update_users.affected_rows`);
    if (reinstatedUser) {
      return true;
    } else return false;
  };

  // Set Context
  const context = {
    // Base State
    editMode,
    setEditMode,
    isSaving,
    setIsSaving,

    // Edit Vars
    active,
    phone,
    role,
    email,
    displayName,
    avatarUrl,
    updatedBy,
    allowedRoles,
    allowedRoleNames,
    allowedCustomers,
    payers,
    payees,
    customers,
    customerId,
    roles,
    allowedCustomerIds,
    allowedRoleIds,
    validationErrors,

    // Set Edit Vars
    setActive,
    setRole,
    setEmail,
    setPhone,
    setDisplayName,
    setUpdatedBy,
    setAvatarUrl,
    setAllowedRoles,
    setAllowedRoleNames,
    setAllowedCustomers,
    setPayers,
    setPayees,
    setCustomers,
    setRoles,
    setCustomerId,
    setAllowedCustomerIds,
    setAllowedRoleIds,
    setValidationErrors,

    // Handler Functions
    handleSaveUserChanges,
    terminateUser,
    reinstateUser,
    handleSave,
    handleCreateUser,
  };

  return <UserDetailsContext.Provider value={context}>{children}</UserDetailsContext.Provider>;
}

const useUserDetails = () => React.useContext(UserDetailsContext);

export { useUserDetails, UserDetailsProvider };
