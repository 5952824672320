import React from 'react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';
import { useData } from '../../DataProvider';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import NotificationManagement from '../reusable/NotificationManagement';

import {
  makeStyles,
  Container,
  Grid,
  Typography,
  IconButton,
  Icon,
  Button,
  Tooltip,
  Menu,
  MenuItem,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import Divide from '../reusable/Divide';

import gql from 'graphql-tag';
import { Subscription } from 'react-apollo';
import * as Sentry from '@sentry/react';

import helpers from '../utils/helpers';
import Loading from '../utils/Loading';
import ImageCarousel from '../reusable/ImageCarousel';
import MoveStatusTracker from '../reusable/MoveStatusTracker';
import MoveDetailsInfo from './moveDetails/MoveDetailsInfo';
import MoveDetailsLocation from './moveDetails/MoveDetailsLocation';
import MoveDetailsTracking from './moveDetails/MoveDetailsTracking';
import MoveTimeModal from './moveDetails/MoveTimeModal';

import MoveCancelModal from './moves/MoveCancelModal';

import MoveDetailsToPrint from './moveDetails/MoveDetailsToPrint';

import { useVehiclePhotos } from '../hooks/useVehiclePhotos';

const log = false;

////////// COMPONENT //////////
function MoveDetails(props) {
  const ctx = useData();
  const cls = useStyles();

  const moveId = props.match.params.id;

  const { getCarouselImagesArray } = useVehiclePhotos();
  const [editMode, setEditMode] = React.useState(false);
  //Move fields
  const [notes, setNotes] = React.useState(``);
  const [actionsOpen, setActionsOpen] = React.useState(null);
  const [modal, setModal] = React.useState({ open: false });
  const [timeModal, setTimeModal] = React.useState({ open: false });
  // const [newPickupTime, setNewPickupTime] = React.useState(null)
  const [imageSelection, setImageSelection] = React.useState(`pickup`);
  const [driver, setDriver] = React.useState([]);
  const [emailsList, setEmailsList] = React.useState([]);

  const getDriver = async moveId => {
    const driverRes = await ctx.apolloClient.query({
      query: GET_DEALER_VIEWABLE_DRIVER_INFO,
      variables: { moveId: moveId },
    });
    if (
      driverRes &&
      driverRes.data &&
      driverRes.data.dealer_viewable_driver_info &&
      driverRes.data.dealer_viewable_driver_info.length > 0
    ) {
      setDriver(driverRes.data.dealer_viewable_driver_info[0]);
    }
  };

  React.useEffect(() => {
    if (moveId) {
      getDriver(moveId);
    }
  }, [moveId]);

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleActionsOpen = event => {
    setActionsOpen(event.currentTarget);
  };
  const handleActionsClose = event => {
    setActionsOpen(null);
  };
  const handleAction = action => {
    handleActionsClose();
    if (action.handler) action.handler();
  };

  const goBack = () => {
    props.history.goBack();
  };

  //Modal Management
  const handleModalOpen = (input = null) => {
    setModal({ ...modal, open: true, input: input });
  };
  const handleModalClose = () => {
    setModal({ ...modal, open: false });
  };
  const handleTimeModalOpen = (input = null) => {
    setTimeModal({ open: true, input: input });
  };
  const handleTimeModalClose = (output = null) => {
    setTimeModal({ ...timeModal, open: false });
    if (output) handleTimeChanges(output.move, output);
  };
  const handleTimeEdit = move => {
    handleTimeModalOpen({
      move: move,
      laneDuration: getPropValue(move, `lane.duration_sec`) || 0,
      readyTime: move.ready_by,
      pickupTime: move.pickup_time,
    });
  };

  // Save changes in time modal
  const handleTimeChanges = async (move, output) => {
    try {
      const variables = {
        id: move.id,
        readyTime: output.pickupTime || move.pickup_time,
        pickupTime: output.pickupTime || move.pickup_time,
        deliveryTime: output.deliveryTime || move.delivery_time,
      };

      const res = await ctx.apolloClient.mutate({
        mutation: UPDATE_TIMES_MOVE_DETAILS,
        variables: variables,
      });
      if (res && res.data && res.data.update_moves && res.data.update_moves.affected_rows > 0) {
        toast.success(`Successfully updated times!`);
      }
    } catch (err) {
      toast.error(`Failed to update times!`);
      console.error(`Failed to update times:`, err);
    }
  };

  // Save changes out of edit mode
  const handleSaveChanges = async move => {
    try {
      const variables = {
        id: move.id,
      };
      if (notes && typeof notes === 'string') {
        variables.move_details = notes;
      }
      if (emailsList && emailsList.length > 0) {
        variables.config = { emailsToNotify: emailsList };
      }
      // if(newPickupTime){
      //   variables.pickup_time = newPickupTime
      // }
      const res = await ctx.apolloClient.mutate({
        mutation: SAVE_MOVE_DETAILS,
        variables: variables,
      });
      if (res && res.data && res.data.update_moves && res.data.update_moves.affected_rows > 0) {
        toast.success(`Successfully updated move!`);
        setEditMode(false);
      }
    } catch (err) {
      toast.error(`Failed to update move!`);
      console.error(`Failed to update move:`, err);
    }
  };

  function handleMoveInvoice(move) {
    let invoice = null;
    try {
      invoice = move.accountsReceivable.invoice;
    } catch (err) {
      console.error('Failed to parse move invoice:', err);
    }
    if (invoice) helpers.createSingleMoveInvoice(invoice);
    else toast.warn('Could not generate invoice PDF. Please refer to the Invoices page for this move.');
  }

  function hideMoveInvoiceButton(move) {
    const moveIsFullyCanceled = move.cancel_status !== null && move.cancel_status !== 'pending';
    if (move.status === 'delivery successful' || moveIsFullyCanceled) return false;
    return true;
  }

  function returnMoveToInverse(move) {
    return { moveToInverse: move };
  }

  let move = null;

  return (
    <>
      <div className={cls.root}>
        <Container maxWidth='lg'>
          {ctx && ctx.firebaseUser && (
            <Subscription
              subscription={GET_MOVE}
              variables={{ moveId: moveId }}
              onError={error => {
                console.error(error);
                Sentry.captureException(error);
              }}
            >
              {({ loading, data }) => {
                if (loading) return <Loading fixed />;
                if (data && data.moves && data.moves.length > 0) {
                  move = data.moves[0];
                  const pickupName = getPropValue(move, `lane.pickup.name`);
                  const deliveryName = getPropValue(move, `lane.delivery.name`);
                  log && console.log(`Move Details:`, move);
                  let pickupPhotos;
                  let deliveryPhotos;
                  let mainImageUrl;

                  if (move && move.pickup_photos && move.pickup_photos.length) {
                    const pickupSignerName =
                      move && move.pickup_workflow_data && move.pickup_workflow_data['signer-name'];
                    pickupPhotos = getCarouselImagesArray({ move: move, type: `pickup`, signer: pickupSignerName });
                    if (pickupPhotos.find(photo => photo.id === 'driver-front')) {
                      mainImageUrl = pickupPhotos.find(photo => photo.id === 'driver-front').original;
                    } else mainImageUrl = null;
                  }

                  if (move && move.delivery_photos && move.delivery_photos.length) {
                    const deliverySignerName =
                      move && move.delivery_workflow_data && move.delivery_workflow_data['signer-name'];
                    deliveryPhotos = getCarouselImagesArray({
                      move: move,
                      type: `delivery`,
                      signer: deliverySignerName,
                    });
                  }

                  const getImageSelection = () => {
                    if (imageSelection === `pickup`) return pickupPhotos;
                    if (imageSelection === `delivery`) return deliveryPhotos;
                  };

                  const globalActions = [
                    {
                      label: `Cancel Move`,
                      handler: () => handleModalOpen(move),
                      hide: move.cancel_status || move.status === `delivery successful`,
                    },
                    {
                      label: `Manage Times`,
                      handler: () => handleTimeEdit(move),
                      disabled:
                        move.status !== null ||
                        move.cancel_status === `started` ||
                        move.cancel_status === `delivered` ||
                        move.cancel_status === `canceled`,
                    },
                    {
                      label: `Download Invoice`,
                      handler: () => handleMoveInvoice(move),
                      hide: hideMoveInvoiceButton(move),
                    },
                  ];

                  return (
                    <>
                      <MoveTimeModal open={timeModal.open} onClose={handleTimeModalClose} input={timeModal.input} />
                      <MoveCancelModal open={modal.open} onClose={handleModalClose} moveInput={modal.input} />

                      <Grid container spacing={1} alignItems='center'>
                        <Grid item>
                          <IconButton className={cls.iconBtn} onClick={() => goBack()}>
                            <Icon>arrow_back</Icon>
                          </IconButton>
                        </Grid>
                        <Grid item xs>
                          <Typography className={cls.head}>Move Details</Typography>
                        </Grid>
                        {editMode ? (
                          <Grid item>
                            <Tooltip placement='top' title={`Click to lock & save your changes`}>
                              <Button className={cls.saveBtn} color='primary' onClick={() => handleSaveChanges(move)}>
                                Save Changes
                              </Button>
                            </Tooltip>
                          </Grid>
                        ) : null}
                        <Grid item>
                          <Tooltip
                            placement='top'
                            title={
                              editMode ? `Click to lock & discard your changes` : `Click to unlock & edit the move`
                            }
                          >
                            <IconButton
                              // style={{
                              //   color: editMode ? theme.palette.error.main : theme.palette.text.secondary,
                              // }}
                              className={cls.iconBtn}
                              onClick={() => handleEditMode()}
                            >
                              <Icon>{editMode ? `lock_open` : `lock`}</Icon>
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Tooltip title={`Move the same vehicle back to its origin`}>
                            <Button
                              disableElevation
                              color='primary'
                              variant='contained'
                              onClick={() => props.history.push('/moves/add', returnMoveToInverse(move))}
                            >
                              Create Inverse
                            </Button>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <MoveDetailsToPrint move={move} pickupPhotos={pickupPhotos} deliveryPhotos={deliveryPhotos} />
                        </Grid>
                        <Grid item>
                          <Tooltip title={`Actions`}>
                            <IconButton className={cls.iconBtn} onClick={handleActionsOpen}>
                              <Icon>settings</Icon>
                            </IconButton>
                          </Tooltip>
                          <Menu
                            keepMounted
                            id={`move-actions-menu`}
                            anchorEl={actionsOpen}
                            open={Boolean(actionsOpen)}
                            onClose={handleActionsClose}
                          >
                            {globalActions.map((action, i) =>
                              !action.hide ? (
                                <MenuItem
                                  key={`move-action-${i}`}
                                  onClick={() => handleAction(action)}
                                  disabled={action.disabled}
                                >
                                  {action.label || `Action ${i + 1}`}
                                </MenuItem>
                              ) : null
                            )}
                          </Menu>
                        </Grid>
                      </Grid>

                      <div className={cls.break_lg} />

                      {/* MOVE STATUS TRACKER */}
                      <MoveStatusTracker move={move} size='large' />

                      <div className={cls.break_lg} />

                      {/* MOVE INFO */}
                      <MoveDetailsInfo
                        move={move}
                        mainImageUrl={mainImageUrl}
                        editMode={editMode}
                        notes={notes}
                        setNotes={setNotes}
                        driver={driver}
                      />

                      {/* EMAIL NOTIFICATION INFO */}
                      {((move.customer && move.customer.config && move.customer.config.allow_email_notifications) ||
                        (move.customer &&
                          move.customer.organization &&
                          move.customer.organization.config &&
                          move.customer.organization.config.allow_email_notifications)) && (
                        <>
                          <Divide
                            spacer
                            tip='View the email addresses that will receive pickup and delivery notification for this move.'
                          >
                            Email Notifications
                          </Divide>
                          <NotificationManagement
                            emailsList={emailsList}
                            setEmailsList={setEmailsList}
                            move={move}
                            editMode={editMode}
                          />
                        </>
                      )}

                      {/* LANE/LOCATION INFO */}
                      <Divide spacer tip='View the pickup & delivery this move is associated with.'>
                        Pickup & Delivery
                      </Divide>
                      <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                          <MoveDetailsLocation type='pickup' move={move} />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <MoveDetailsLocation type='delivery' move={move} />
                        </Grid>
                      </Grid>

                      {/* TRACKING */}
                      <Divide spacer tip='Track where the move is currently located'>
                        Tracking
                      </Divide>
                      <MoveDetailsTracking move={move} />

                      {/* IMAGES */}
                      {(pickupPhotos && pickupPhotos.length) || (deliveryPhotos && deliveryPhotos.length) ? (
                        <>
                          <Divide spacer tip='View images from the pickup and delivery.'>
                            Images
                          </Divide>
                          <div className={cls.paper}>
                            <TextField
                              select
                              fullWidth
                              size='small'
                              variant='outlined'
                              label='Image Set Selection'
                              placeholder='Select an image set...'
                              value={imageSelection}
                              onChange={e => setImageSelection(e.target.value)}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                                    <Icon color='disabled' fontSize='small'>
                                      burst_mode
                                    </Icon>
                                  </InputAdornment>
                                ),
                              }}
                            >
                              <MenuItem value='pickup'>Pickup Images - {pickupName}</MenuItem>
                              <MenuItem value='delivery'>Delivery Images - {deliveryName}</MenuItem>
                            </TextField>

                            <div className={cls.break_sm} />

                            <ImageCarousel size='lg' images={getImageSelection()} />
                          </div>
                        </>
                      ) : null}
                    </>
                  );
                } else
                  return (
                    <div className={cls.notFound}>
                      <Typography className={cls.notFoundTxt}>NO MOVE RECORD FOUND</Typography>
                    </div>
                  );
              }}
            </Subscription>
          )}
        </Container>
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  iconBtn: {
    width: 40,
    height: 40,
  },
  head: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  notFound: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  break_sm: {
    width: '100%',
    height: theme.spacing(1),
  },
  break_lg: {
    width: '100%',
    height: theme.spacing(3),
  },
}));

////////// GRAPHQL //////////
const GET_MOVE = gql`
  subscription get_moves($moveId: bigint!) {
    moves(where: { id: { _eq: $moveId } }) {
      id
      cancel_reason
      cancel_status
      config
      consumer_at_pickup
      consumer_name
      consumer_phone
      consumer_pickup
      consumer_type
      dealer_contact
      deliver_by
      delivery_arrived
      delivery_started
      delivery_stop_id
      delivery_successful
      delivery_time
      driver_app_version
      driver_id
      driver_name
      manual_flag
      move_details
      move_type
      pickup_arrived
      pickup_started
      pickup_stop_id
      pickup_successful
      pickup_time
      ready_by
      reference_num
      status
      tags
      tracking_link
      vehicle_color
      vehicle_make
      vehicle_model
      vehicle_odometer
      vehicle_stock
      vehicle_vin
      vehicle_year
      pickup_workflow_data
      delivery_workflow_data
      accountsReceivable {
        invoice {
          id
          accounting_num
          start_datetime
          end_datetime
          status
          customer {
            id
            name
            address
            billing_frequency
            payment_terms
            auto_pay
            notify_billing
            paymentmethods(where: { type: { _eq: "manual" } }) {
              id
            }
          }
          arpayments(order_by: { createdat: asc }) {
            id
            amount
            status
            accounting_id
            gateway_transaction_id
            createdat
          }
          armoves(
            where: { active: { _eq: 1 }, type: { _eq: "move" }, move_id: { _eq: $moveId } }
            order_by: { move_id: asc }
          ) {
            id
            accounting_item_id
            active
            arevent_id
            author
            billable_datetime
            discount_amount
            discount_reason
            dispute_reason
            disputed
            due_amount
            invoice_id
            move_id
            notes
            paid_amount
            status
            type
            invoice {
              id
              accounting_num
              customer {
                id
                accounting_id
              }
            }
            move {
              id
              chargeable
              class
              consumer_name
              customer_id
              delivery_arrived
              delivery_started
              delivery_stop_id
              delivery_successful
              pickup_arrived
              pickup_started
              pickup_successful
              reference_num
              status
              vehicle_color
              vehicle_make
              vehicle_model
              vehicle_odometer
              vehicle_odometer
              vehicle_stock
              vehicle_vin
              vehicle_year
              lane {
                id
                dealer_base_discount
                dealer_stranded_discount
                description
                distance_miles
                tolls
                delivery {
                  id
                  address
                  name
                }
                pickup {
                  id
                  address
                  name
                }
              }
              raterule {
                id
                rate
                type
              }
              customer {
                id
                name
                address
                billing_frequency
                payment_terms
                auto_pay
              }
            }
            details {
              id
              name
              notes
              amount
            }
            revisions(order_by: { revision: desc }) {
              id
              discount_amount
              discount_reason
              dispute_reason
              disputed
              due_amount
              revision
              details {
                id
                name
                notes
                amount
              }
            }
          }
        }
      }
      customer {
        id
        name
        config
        organization {
          config
        }
      }
      driver {
        id
      }
      lane {
        id
        description
        distance_miles
        dealer_stranded_price
        dealer_base_price
        pickup {
          id
          name
          address
          nickname
          latitude
          longitude
        }
        delivery {
          id
          name
          address
          nickname
          latitude
          longitude
        }
      }
      moveByReturnRideId {
        id
        consumer_at_pickup
        consumer_name
        consumer_phone
        consumer_pickup
        consumer_type
        dealer_contact
        manual_flag
        move_type
        reference_num
        tags
        vehicle_color
        vehicle_make
        vehicle_model
        vehicle_odometer
        vehicle_stock
        vehicle_vin
        vehicle_year
      }
      parent_move {
        id
        consumer_at_pickup
        consumer_name
        consumer_phone
        consumer_pickup
        consumer_type
        dealer_contact
        manual_flag
        move_type
        reference_num
        tags
        vehicle_color
        vehicle_make
        vehicle_model
        vehicle_odometer
        vehicle_stock
        vehicle_vin
        vehicle_year
      }
      parentMove {
        id
        consumer_at_pickup
        consumer_name
        consumer_phone
        consumer_pickup
        consumer_type
        dealer_contact
        manual_flag
        move_type
        reference_num
        tags
        vehicle_color
        vehicle_make
        vehicle_model
        vehicle_odometer
        vehicle_stock
        vehicle_vin
        vehicle_year
      }
      childMoves {
        id
        consumer_at_pickup
        consumer_name
        consumer_phone
        consumer_pickup
        consumer_type
        dealer_contact
        manual_flag
        move_type
        reference_num
        tags
        vehicle_color
        vehicle_make
        vehicle_model
        vehicle_odometer
        vehicle_stock
        vehicle_vin
        vehicle_year
      }
      payer {
        id
        name
      }
      delivery_photos: vehiclephotos(where: { workflow: { type: { _eq: "delivery" } } }) {
        id
        step_id
        name
        url
        location
      }
      pickup_photos: vehiclephotos(where: { workflow: { type: { _eq: "pickup" } } }) {
        id
        step_id
        name
        url
        location
      }
      pickup_workflow_data
      delivery_workflow_data
      workflowset {
        id
        pickupWorkflow {
          id
          steps
        }
        deliveryWorkflow {
          id
          steps
        }
        fuelWorkflow {
          id
          steps
        }
      }
    }
  }
`;

const SAVE_MOVE_DETAILS = gql`
  mutation save_move_details($id: bigint!, $move_details: String, $config: jsonb) {
    update_moves(
      where: { id: { _eq: $id } }
      _set: { move_details: $move_details, config: $config, updatedat: "now()" }
    ) {
      affected_rows
      returning {
        id
        active
        auto_assign
        chargeable
        class
        config
        consumer_name
        consumer_phone
        consumer_pickup
        dealer_contact
        delivery_template_override
        lyft_flag
        manual_flag
        move_details
        move_type
        payable
        pickup_template_override
        priority
        rate_class_override
        reference_num
        ride_type
        tags
        vehicle_color
        vehicle_make
        vehicle_model
        vehicle_odometer
        vehicle_stock
        vehicle_vin
        vehicle_year
        updatedat
      }
    }
  }
`;

const UPDATE_TIMES_MOVE_DETAILS = gql`
  mutation update_times_move_details(
    $id: bigint!
    $readyTime: timestamptz
    $pickupTime: timestamptz
    $deliveryTime: timestamptz
  ) {
    update_moves(
      where: { id: { _eq: $id } }
      _set: { ready_by: $readyTime, pickup_time: $pickupTime, delivery_time: $deliveryTime, updatedat: "now()" }
    ) {
      affected_rows
      returning {
        id
        ready_by
        pickup_time
        delivery_time
        updatedat
      }
    }
  }
`;

const GET_DEALER_VIEWABLE_DRIVER_INFO = gql`
  query get_dealer_viewable_driver_info($moveId: bigint!) {
    dealer_viewable_driver_info(where: { move_id: { _eq: $moveId } }) {
      avatar_url
      display_name
      driver_id
    }
  }
`;

////////// EXPORT //////////
export default withRouter(MoveDetails);
