import { gql } from 'graphql-tag';

const GET_USERS = gql`
query get_users($customerIds: [bigint!]!) {
  users(where: { customer_id: { _in: $customerIds } }) {
    active
    avatar_url
    created_at
    default_role
    display_name
    email
    id
    updated_at
    customer {
      name
    }
  }
}
`;

const GET_CUSTOMERS = gql`
  query get_customer($customerId: bigint!) {
    customers(where: { id: { _eq: $customerId } }) {
      id
      name
      organization {
        customers {
          id
          name
        }
      }
    }
  }
`;

const GET_USERS_BY_ALLOWED_CUSTOMER = gql`
query get_users_by_allowed_customer($customerIds: [bigint!]!) {
  usertocustomers(where: { customer_id: { _in: $customerIds } }) {
    user {
      active
      avatar_url
      created_at
      default_role
      display_name
      email
      id
      updated_at
      customer {
        name
      }
    }
  }
}
`

const GET_ALL_DEALERS = gql`
query get_all_dealers {
  users(where: { default_role: {_ilike: "%dealer%"} }) {
    active
    avatar_url
    created_at
    default_role
    display_name
    email
    id
    updated_at
    customer {
      name
    }
  }
}
`

export { GET_USERS, GET_CUSTOMERS, GET_USERS_BY_ALLOWED_CUSTOMER, GET_ALL_DEALERS };