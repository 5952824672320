//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { UserDetailsProvider } from './UserDetailsProvider';
import { getUserRole } from '../../utils/authHelper'
import Toolbar from '../../reusable/Toolbar';
import DefaultEmptyFallback from '../../reusable/Fallbacks/DefaultEmptyFallback';
import CustomerUserAddToolbar from './UserAddToolbar';
import CustomerUserAddForm from './UserAddForm';

//////////////////////// COMPONENT ////////////////////////

export default function UserAdd(props) {
  const cls = useStyles();

  const user = {};

  const [role, setRole] = React.useState(null);

  const fetchRole = async () => {
    const userRole = await getUserRole()
    setRole(userRole)
  }

  React.useEffect(() => {
    fetchRole()
  }, [])

  if (role && role.includes('admin')) {
    return (
    <UserDetailsProvider user={user} >
    <div className={cls.root}>
      <Toolbar fullscreen title={`Add User`} back>
        <CustomerUserAddToolbar/>
      </Toolbar>

      <Spacer size='xl' />

      <CustomerUserAddForm/>

      <Spacer size='xl' />
    </div>
    </UserDetailsProvider>
  );
    } else return (
      <div className={cls.root}>
        <DefaultEmptyFallback message='RESTRICTED ACCESS' />
      </div>
    )
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    background: theme.palette.background.paper,
  },
}));

