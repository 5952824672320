import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Tooltip, Grid, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import MoveItemTooltip from './MoveItemTooltip';
import sdk from '@hopdrive/sdk';
let log = false;

export default function OverviewMoveItem(props) {
  const cls = useStyles();

  const { move } = props;

  const [status, setStatus] = useState('');

  //Display time move is scheduled hh:mm a
  let moveISO = move && move.ready_by;
  let moveTime = DateTime.fromISO(moveISO);
  let pickupTime = moveTime.toLocaleString(DateTime.TIME_SIMPLE);

  //Show consumer name on move if it is a concierge move
  let consumerName = move && move.consumer_name ? move.consumer_name : '';
  let consumerPhone = move && move.consumer_phone ? move.consumer_phone : '';

  //Basic vehicle description
  let vehicleColor = move && move.vehicle_color ? move.vehicle_color : '';
  let vehicleDescription = `${sdk.utilities.toProperCase(vehicleColor)} ${move.vehicle_make} ${move.vehicle_model}`;

  //Set color and pulse based on move status
  let moveStatus = move.status;
  //Check cancel status in case move's status has not been set to canceled
  let moveCanceled = move.cancel_status && (move.cancel_status === 'canceled' || move.cancel_status === 'started');

  useEffect(() => {
    if (moveStatus) {
      if (moveStatus === `dispatched` || moveStatus === null) {
        //Move not yet started
        setStatus('');
      } else if (moveStatus === `delivery successful`) {
        //move completed
        setStatus('Completed');
      } else if (moveStatus === `canceled` || moveCanceled) {
        //move completed
        setStatus('Canceled');
      } else {
        // move in progress
        setStatus('InProgress');
      }
    }
  }, [move]);

  log && console.log('MOVE-ITEM', moveISO, moveTime, pickupTime);

  return (
    <Tooltip
      classes={{ tooltip: cls.MoveTooltip }}
      enterDelay={500}
      leaveDelay={200}
      title={<MoveItemTooltip move={move} />}
    >
      <Link to={`/moves/${move.id}`} target='_blank' rel='noopener noreferrer'>
        <div key={`move-item-${move.id}`} className={cls['MoveItem' + status]}>
          {/* Only show this text if move is completed or canceled */}
          {status === 'Canceled' || status === 'Completed' ? (
            <Typography className={cls.KeyText}>{status === 'Canceled' ? 'CANCELED' : 'COMPLETED'}</Typography>
          ) : null}
          {/* Always show pickup time */}
          <Typography className={cls.KeyText}>{pickupTime}</Typography>
          {/* Show consumer name if it is a concierge move */}
          {consumerName ? <Typography className={cls.KeyText}>{consumerName}</Typography> : <></>}
          {/* Show consumer phone if it is a concierge move- else show vehicle info */}
          {consumerPhone ? (
            <Typography className={cls.MoveText}>{consumerPhone}</Typography>
          ) : (
            <Typography className={cls.MoveText}>{vehicleDescription}</Typography>
          )}{' '}
        </div>
      </Link>
    </Tooltip>
  );
}

const useStyles = makeStyles(theme => ({
  MoveItem: {
    color: '#fff',
    margin: '4px',
    padding: theme.spacing(1),
    borderRadius: '.55rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.primary.main,
  },
  MoveItemCompleted: {
    color: '#fff',
    margin: '4px',
    padding: theme.spacing(1),
    borderRadius: '.55rem',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.fade[3],
    justifyContent: 'space-around',
  },
  MoveItemCanceled: {
    color: '#fff',
    margin: '4px',
    padding: theme.spacing(1),
    borderRadius: '.55rem',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: "#000",
    justifyContent: 'space-around',
  },
  MoveItemInProgress: {
    color: '#fff',
    margin: '4px',
    padding: theme.spacing(1),
    borderRadius: '.55rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.primary.main,
    animation: `pulse 1s infinite`,
  },
  MoveTooltip: {
    width: '700px',
  },
  KeyText: {
    fontWeight: 600,
    margin: '4px',
    lineHeight: '1.25',
    fontSize: 14,
  },
  MoveText: {
    lineHeight: '1.25',
    fontSize: 12,
  },
}));
