import React from 'react';
import moment from 'moment';
import { getLatestToken } from '../../utils/authHelper'
import { useVehiclePhotos } from '../../hooks/useVehiclePhotos';

import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { makeStyles, Grid, Typography, TextField, MenuItem, Button, Icon } from '@material-ui/core';

import Loading from '../../utils/Loading';

const log = false;

//////////////////////////////////////// COMPONENT ////////////////////////////////////////
export default function AppraisalDetailsCarousel(props) {
  const cls = useStyles();

  const { appraisal, vehicleImages, setVehicleImages } = props;
  const { sortImages } = useVehiclePhotos();
  let usableMoves = [];
  if (appraisal && appraisal.moves && appraisal.moves.length > 0)
    usableMoves = appraisal.moves.filter(move => move.vehiclephotos && move.vehiclephotos.length > 0);

  const [selectedMove, setSelectedMove] = React.useState(usableMoves.length > 0 ? usableMoves[0].id : null);
  const [galleryPics, setGalleryPics] = React.useState([]);
  const [lightboxPics, setLightboxPics] = React.useState([]);
  const [lightboxOpen, setLightboxOpen] = React.useState(false);
  const [picIndex, setPicIndex] = React.useState(0);
  const [imageFilter, setImageFilter] = React.useState(null);

  const galleryRef = React.useRef(null);

  React.useEffect(() => {
    handleFilterChange();
  }, [vehicleImages]);

  // Get related move images
  const getVehicleImages = async move => {
    let images = []

    if (move.vehiclephotos && move.vehiclephotos.length > 0) {
      let deliveryPhotos = move.vehiclephotos.filter(photo => photo.workflow_id === 2);
      let pickupPhotos = move.vehiclephotos.filter(photo => photo.workflow_id === 1);

      if (deliveryPhotos && deliveryPhotos.length > 0) {
        images = deliveryPhotos
      } else if (pickupPhotos && pickupPhotos.length > 0) {
        images = pickupPhotos
      }
    }

    // Sort the images before setting them (and add an index field)
    images = sortImages(images);
    images = images.map((image, i) => ({
      label: image && image.name ? image.name.replaceAll(`-`, ` `) : ``,
      original: image.url,
      thumbnail: image.url,
      index: i,
    }));

    // Set final image state
    setVehicleImages(images);
  };

  React.useEffect(() => {
    getVehicleImages(usableMoves.length > 0 ? usableMoves[0] : null);
  }, []);

  const handleSelectedMoveChange = event => {
    const moveId = event.target.value;
    setSelectedMove(moveId);

    const move = usableMoves.find(usableMove => usableMove.id === moveId);
    getVehicleImages(move);
  };

  const handleFilterChange = (str = null) => {
    let images = [...vehicleImages];

    if (images && images.length > 0) {
      if (str === `exterior`)
        images = images.filter(image => image.label.includes(`driver`) || image.label.includes(`passenger`) || image.label.includes(`exterior`));
      if (str === `interior`)
        images = images.filter(image => !image.label.includes(`driver`) && !image.label.includes(`passenger`));
      if (str === `wheel`) images = images.filter(image => image.label.includes(`wheel`));
      if (str === `damage`) images = images.filter(image => image.label.includes(`damage`));

      setPicIndex(0);
      setGalleryPics(images);
      setLightboxPics(images.map(image => image.original));
      setImageFilter(str);
    }
  };

  const handleFullscreen = event => {
    let currentIndex = 0;
    if (galleryRef) currentIndex = galleryRef.current.state.currentIndex;
    if (lightboxPics && lightboxPics.length > 0) {
      setPicIndex(currentIndex);
      setLightboxOpen(!lightboxOpen);
    }
  };

  return (
    <>
      <div className={cls.paper}>

          <>
            <TextField
              select
              fullWidth
              label='Select Move Images'
              variant='outlined'
              size='small'
              value={selectedMove}
              onChange={handleSelectedMoveChange}
            >
              {usableMoves &&
                usableMoves.length > 0 &&
                usableMoves.map(move => (
                  <MenuItem key={`move-select-${move.id}`} value={move.id}>
                    #{move.id} - {moment(move.pickup_time).format(`MM/DD/YYYY`)} - {move.lane.description}
                  </MenuItem>
                ))}
            </TextField>

            <div className={cls.xsBreak} />

            <Grid container spacing={1} justifyContent='space-between'>
              <Grid item xs>
                <Button
                  className={!imageFilter ? cls.buttonActive : cls.button}
                  fullWidth
                  color='secondary'
                  variant='contained'
                  onClick={() => handleFilterChange()}
                >
                  Virtual&nbsp;Walkaround
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={imageFilter === `exterior` ? cls.buttonActive : cls.button}
                  fullWidth
                  color='secondary'
                  variant='contained'
                  onClick={() => handleFilterChange(`exterior`)}
                >
                  Exterior{props.width === `xl` ? ` Images` : ``}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={imageFilter === `interior` ? cls.buttonActive : cls.button}
                  fullWidth
                  color='secondary'
                  variant='contained'
                  onClick={() => handleFilterChange(`interior`)}
                >
                  Interior{props.width === `xl` ? ` Images` : ``}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={imageFilter === `wheel` ? cls.buttonActive : cls.button}
                  fullWidth
                  color='secondary'
                  variant='contained'
                  onClick={() => handleFilterChange(`wheel`)}
                >
                  Wheel{props.width === `xl` ? ` Images` : ``}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={imageFilter === `damage` ? cls.buttonActive : cls.button}
                  fullWidth
                  color='secondary'
                  variant='contained'
                  onClick={() => handleFilterChange(`damage`)}
                >
                  Damage{props.width === `xl` ? ` Images` : ``}
                </Button>
              </Grid>
            </Grid>

            <div className={cls.mdBreak} />

            {galleryPics.length > 0 ? (
              <div className={cls.carousel}>
                <Gallery
                  ref={galleryRef}
                  items={galleryPics}
                  thumbnailPosition='left'
                  lazyLoad
                  showIndex
                  disableKeyDown
                  startIndex={picIndex}
                  slideDuration={250}
                  showFullscreenButton={false}
                  onClick={handleFullscreen}
                />
                <Icon className={cls.fullscreenIcon} onClick={handleFullscreen}>
                  fullscreen
                </Icon>
              </div>
            ) : (
              <>
                <Typography className={cls.notFoundTxt}>NO IMAGES FOUND</Typography>
              </>
            )}

            {lightboxOpen && lightboxPics.length > 0 ? (
              <Lightbox
                mainSrc={lightboxPics[picIndex]}
                nextSrc={lightboxPics[(picIndex + 1) % lightboxPics.length]}
                prevSrc={lightboxPics[(picIndex + lightboxPics.length - 1) % lightboxPics.length]}
                onCloseRequest={() => setLightboxOpen(false)}
                onMovePrevRequest={() => setPicIndex((picIndex + lightboxPics.length - 1) % lightboxPics.length)}
                onMoveNextRequest={() => setPicIndex((picIndex + 1) % lightboxPics.length)}
                reactModalStyle={{
                  overlay: {
                    zIndex: 1000000,
                  },
                  content: {
                    zIndex: 1000000,
                  },
                }}
              />
            ) : null}
          </>
      </div>
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////
const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: '#fff',
    boxShadow: '0 0 12px #00000032',
  },
  carousel: {
    position: 'relative',
  },
  fullscreenIcon: {
    position: 'absolute',
    bottom: 16,
    right: 16,
    color: '#fff',
    filter: 'drop-shadow(2px 2px 2px #00000080)',
    fontSize: 48,
    '&:hover, &:active': {
      color: theme.palette.info.main,
      transform: 'scale(1.1)',
    },
    cursor: 'pointer',
    transition: '0.2s',
  },
  button: {
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    background: 'transparent',
    boxShadow: 'none',
    '&:hover, &:active': {
      background: `${theme.palette.secondary.main}32`,
      boxShadow: 'none',
    },
    transition: '0.2s',
  },
  buttonActive: {
    border: `1px solid ${theme.palette.secondary.main}`,
    color: '#fff',
    boxShadow: 'none',
    '&:hover, &:active': {
      boxShadow: 'none',
    },
    transition: '0.2s',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  xsBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  smBreak: {
    width: '100%',
    height: theme.spacing(1.5),
  },
  mdBreak: {
    width: '100%',
    height: theme.spacing(2),
  },
  lgBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
}));
