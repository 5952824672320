import React from 'react';
import { toast } from 'react-toastify';
import { makeStyles, Typography, LinearProgress } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../reusable/Modal';

import { useUserDetails } from './UserDetailsProvider';

//////////////////////// COMPONENT ////////////////////////

export default function ReinstateModalContent({ input, onClose }) {
  const cls = useStyles();
  const ctx = useUserDetails();

  const [isSaving, setIsSaving] = React.useState(false);

  // Handle submitting the form
  const handleSubmit = async () => {
    const res = await ctx.reinstateUser(input.user.id);
    if (res) {
      toast.success(`Successfully reinstated user!`, { autoClose: 2500 });
      if (onClose) onClose();
    } else {
      toast.error(`Failed to reinstate user, please contact an admin!`);
    }

    setIsSaving(false);
  };

  return (
    <>
      <ModalHeader onClose={onClose}>Reinstate User</ModalHeader>

      <ModalContent>
        <Typography className={cls.subtitle}>
          By clicking "submit" below, the user's login credentials will be reinstated. They will receive an email prompting them to set a new password,
          at which point they will be able to access our portals with all of their prior permissions. If you would like to change these permissions, you can edit them from the User Details page.
        </Typography>

        <Spacer />
         
      </ModalContent>

      <div className={cls.background}>{isSaving ? <LinearProgress /> : null}</div>

      <ModalFooter>
        <ModalAction
          loading={isSaving}
          disabled={isSaving}
          color='primary'
          onClick={() => {
            setIsSaving(true);
            handleSubmit();
          }}
        >
          Submit
        </ModalAction>

        <ModalAction onClick={() => onClose()}>Close</ModalAction>
      </ModalFooter>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  subtitle: {
    lineHeight: 1.333,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
}));