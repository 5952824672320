//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import sdk from '@hopdrive/sdk';
import { getUserName, getUserRole } from '../../utils/authHelper.js';

import { makeStyles, Grid, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../reusable/Modal';

//////////////////////// COMPONENT ////////////////////////

function MoveTimeModal(props) {
  const cls = useStyles();

  let userName = getUserName()

  const { open, onClose, input } = props;

  const [myPickupDate, setMyPickupDate] = React.useState(``);
  const [myPickupTime, setMyPickupTime] = React.useState(``);
  const [userRole, setUserRole] = React.useState(``);

  const fetchUserRole = async () => {
    const role = await getUserRole();
    setUserRole(role)
  }

  React.useEffect(() => {
    fetchUserRole()
  }, []);

  React.useEffect(() => {
    if (input) {
      setMyPickupDate(input.pickupTime);
      setMyPickupTime(input.pickupTime);
    }
  }, [input]);

  const handleDateChange = str => event => {
    if (str === `pickup`) setMyPickupDate(dayjs(event).startOf(`day`).format());
  };

  const handleTimeChange = str => event => {
    if (str === `pickup`) setMyPickupTime(dayjs(event).format());
  };

  const calcDeliveryTime = (pickupTime, laneDuration) => {
    if (!pickupTime) return null;
    if (!laneDuration) return pickupTime;
    return dayjs(pickupTime).add(laneDuration, `second`);
  };

  const handleSubmit = async () => {
    let newPickupTime = `${dayjs.utc(myPickupDate).format(`YYYY-MM-DD`)}T${dayjs
      .utc(myPickupTime)
      .format(`HH:mm`)}:00+00:00`;

    // Insert move update eventlog
    if (input.pickupTime !== newPickupTime ){
      let metadataText = {oldPickupTime: `${input.pickupTime}`, newPickupTime: `${newPickupTime}`, oldReadyTime: `${input.readyTime}`, newReadyTime: `${newPickupTime}`}
      try {
        let eventConfig = {
          action: `move.times.updated`,
          user: userName,
          role: userRole,
          move_id: input.move.id,
          customer_id: input.move.customer.id,
          lane_id: input.move.lane.id,
          driver_id: input.move.driver_id || null,
          metadata: metadataText || null
        };
        let logRes = await sdk.events.buildAndCreate(eventConfig);
        console.log(`Successfully inserted eventlog ${logRes.id}`);
      }  catch (err) {
        //Can't return bc other functions need to run
        console.error(err);
      }
    }

    // Update the delivery_time based on the selected pickup_time
    const newDeliveryTime = calcDeliveryTime(newPickupTime, input.laneDuration);

    handleClose({
      move: input.move,
      pickupTime: newPickupTime,
      deliveryTime: newDeliveryTime,
    });
  };

  const handleClose = (output = null) => {
    if (onClose) onClose(output);
  };

  return (
    <>
      <Modal open={open}>
        <ModalHeader handleClose={handleClose}>Edit Times</ModalHeader>

        <ModalContent
          subtitle={`Edit the time at which the move should be picked up.`}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>

            <Typography className={cls.titleTxt}>Pickup Time</Typography>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs>
                <DatePicker
                  autoOk
                  fullWidth
                  variant='inline'
                  orientation='portrait'
                  format='MM/dd/yyyy'
                  label='Pickup Date'
                  size='small'
                  inputVariant='outlined'
                  value={myPickupDate}
                  onChange={handleDateChange(`pickup`)}
                />
              </Grid>
              <Grid item xs>
                <TimePicker
                  autoOk
                  fullWidth
                  minutesStep={5}
                  variant='inline'
                  orientation='portrait'
                  label='Pickup Time'
                  size='small'
                  inputVariant='outlined'
                  value={myPickupTime}
                  onChange={handleTimeChange(`pickup`)}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </ModalContent>

        <ModalFooter>
          <ModalAction onClick={() => handleSubmit()} color='primary'>
            Save Times
          </ModalAction>
          <ModalAction onClick={() => handleClose()}>Cancel</ModalAction>
        </ModalFooter>
      </Modal>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  titleTxt: {
    marginBottom: theme.spacing(1),
    fontSize: 16,
    fontWeight: 500,
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));

//////////////////////// EXPORT ////////////////////////

export default MoveTimeModal;
