import React from 'react';
import { makeStyles, TextField, MenuItem, InputAdornment, Icon, Typography } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

//////////////////////// COMPONENT ////////////////////////

export default function WorkflowForm({ workflowSets, workflow, onWorkflowChange }) {
  const cls = useStyles();

  const handleWorkflowChange = value => {
    if (onWorkflowChange && workflowSets && workflowSets.length) {
      const newWorkflowSet = workflowSets.find(ws => ws.id === value);
      onWorkflowChange(newWorkflowSet || {});
    }
  };

  if (workflowSets && workflowSets.length) {
    return (
      <>
        <Spacer />

        <TextField
          select
          fullWidth
          label='Workflow Selection'
          placeholder='Default'
          variant='outlined'
          size='small'
          value={workflow.id || 1}
          onChange={e => handleWorkflowChange(e.target.value)}
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    file_copy
                  </Icon>
                </InputAdornment>
              </>
            ),
          }}
        >
          {workflowSets.map((ws, i) => (
            <MenuItem key={`allowed-workflow-${i}`} className={cls.menuItem} value={ws.id}>
              <Typography className={cls.nameTxt}>{ws.name}</Typography>
              <Typography className={cls.descTxt}>{ws.description}</Typography>
            </MenuItem>
          ))}
        </TextField>
      </>
    );
  }

  return null;
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  menuItem: {
    display: 'block',
  },
  nameTxt: {
    marginBottom: theme.spacing(0.5),
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 400,
  },
  descTxt: {
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
}));
