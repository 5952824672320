//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import {
  makeStyles,
  TableRow,
  TableCell,
  Collapse,
  Grid,
  Typography,
  Tooltip,
  Icon,
  IconButton,
  TextField,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function ArmoveFold(props) {
  const cls = useStyles();
  const {
    goToLocationDetails,
    capFirst,
    getFormattedVehicleFromMove,
    getMinutesBetween,
    getMonetaryValue,
    clampNegNum,
  } = useTools();

  const { armove, actions, rowCellCount, foldId } = props;

  const move = armove.move || {};
  const revisions = armove.revisions || [];

  const [actionsOpen, setActionsOpen] = React.useState(null);

  const handleActionsOpen = event => {
    setActionsOpen(event.currentTarget);
  };
  const handleActionsClose = event => {
    setActionsOpen(null);
  };

  const handleAction = action => {
    handleActionsClose();
    if (action.handler) action.handler();
  };

  const currentRevisionNum = revisions && revisions.length > 0 ? revisions[0].revision + 1 : 1;
  const [revisionNum, setRevisionNum] = React.useState(currentRevisionNum);
  const getSelectedRevision = () => {
    if (currentRevisionNum === revisionNum) return armove || {};
    else {
      for (let i = 0; i < revisions.length; i++) {
        if (revisions[i].revision === revisionNum) return revisions[i] || {};
      }
    }
  };
  const selectedRevision = getSelectedRevision();

  React.useEffect(() => {
    if (armove) setRevisionNum(currentRevisionNum);
  }, [armove, currentRevisionNum]);

  const total = selectedRevision ? selectedRevision.due_amount - selectedRevision.discount_amount : 0;
  const paid = selectedRevision ? selectedRevision.paid_amount : 0;
  const due = total - paid;

  let lineItems =
    selectedRevision && selectedRevision.details && selectedRevision.details.length > 0
      ? selectedRevision.details.map(ar => {
          let name = capFirst(ar.name);
          let notes = capFirst(ar.notes);
          let amount = ar.amount;

          if (ar.name.includes(`INVALID`)) name = ` INVALID Transport Cost`;
          else if (ar.name.includes(`Transport`)) name = ` Transport Cost`;
          else if (!ar.name.includes(`Promo`) && !ar.name.includes(`promo`)) name = `Accessorial: ${capFirst(ar.name)}`;
          else name = capFirst(ar.name);

          if (ar.amount < 0) amount = `(${getMonetaryValue(Math.abs(amount))})`;
          else amount = `${getMonetaryValue(amount)}`;

          return { name: name, notes: notes, amount: amount };
        })
      : [];

  lineItems = lineItems.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  const formatPickupStarted = dayjs(move.pickup_started).format(`h:mm A`);
  const formatPickupArrived = dayjs(move.pickup_arrived).format(`h:mm A`);
  const formatPickupSuccessful = dayjs(move.pickup_successful).format(`h:mm A`);
  const formatDeliveryStarted = dayjs(move.delivery_started).format(`h:mm A`);
  const formatDeliveryArrived = dayjs(move.delivery_arrived).format(`h:mm A`);
  const formatDeliverySuccessful = dayjs(move.delivery_successful).format(`h:mm A`);

  const pickupDrive = getMinutesBetween(move.pickup_started, move.pickup_arrived);
  const pickupInspect = getMinutesBetween(move.pickup_arrived, move.pickup_successful);
  const pickupDwell = getMinutesBetween(move.pickup_successful, move.delivery_started);
  const deliveryDrive = getMinutesBetween(move.delivery_started, move.delivery_arrived);
  const deliveryInspect = getMinutesBetween(move.delivery_arrived, move.delivery_successful);

  const pickupTotal = pickupDrive + pickupInspect + pickupDwell;
  const deliveryTotal = deliveryDrive + deliveryInspect;

  const totalTime = pickupDrive + pickupInspect + pickupDwell + deliveryDrive + deliveryInspect;

  const useBarStyles = makeStyles(theme => ({
    p1: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${pickupTotal && !isNaN(pickupTotal) ? (pickupDrive / pickupTotal) * 100 : 33.333}%`,
      height: theme.spacing(0.5),
      background: theme.palette.primary.main,
    },
    p2: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${pickupTotal && !isNaN(pickupTotal) ? (pickupInspect / pickupTotal) * 100 : 33.333}%`,
      height: theme.spacing(0.5),
      background: theme.palette.secondary.main,
    },
    p3: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${pickupTotal && !isNaN(pickupTotal) ? (pickupDwell / pickupTotal) * 100 : 33.333}%`,
      height: theme.spacing(0.5),
      background: theme.palette.secondary.light,
    },
    d1: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${deliveryTotal && !isNaN(deliveryTotal) ? (deliveryDrive / deliveryTotal) * 100 : 50}%`,
      height: theme.spacing(0.5),
      background: theme.palette.primary.main,
    },
    d2: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${deliveryTotal && !isNaN(deliveryTotal) ? (deliveryInspect / deliveryTotal) * 100 : 50}%`,
      height: theme.spacing(0.5),
      background: theme.palette.secondary.main,
    },
  }));
  const barCls = useBarStyles();
  const pickup = move && move.lane && move.lane.pickup ? move.lane.pickup : {};
  const delivery = move && move.lane && move.lane.delivery ? move.lane.delivery : {};

  return (
    <TableRow>
      <TableCell className={cls.cell} colSpan={rowCellCount}>
        <Collapse className={cls.collapse} in={foldId === armove.id}>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <div className={cls.section}>
                <div className={cls.block}>
                  <Typography className={cls.headTxt}>Pickup Details</Typography>

                  <Tooltip placement='top' title='Click to view details for this location'>
                    <div className={cls.location} onClick={() => goToLocationDetails(pickup.id)}>
                      <Typography className={cls.locationTxt}>{pickup.name}</Typography>
                      <Typography className={cls.locationTxt}>{pickup.address}</Typography>
                    </div>
                  </Tooltip>

                  {pickupDrive || pickupTotal === 0 ? (
                    <Tooltip
                      title={`Pickup Drive - ${
                        pickupDrive ? `${pickupDrive} minutes (${formatPickupStarted} to ${formatPickupArrived})` : `0m`
                      }`}
                    >
                      <div className={barCls.p1} />
                    </Tooltip>
                  ) : null}

                  {pickupInspect || pickupTotal === 0 ? (
                    <Tooltip
                      title={`Pickup Inspection - ${
                        pickupInspect
                          ? `${pickupInspect} minutes (${formatPickupArrived} to ${formatPickupSuccessful})`
                          : `0m`
                      }`}
                    >
                      <div className={barCls.p2} />
                    </Tooltip>
                  ) : null}

                  {pickupDwell || pickupTotal === 0 ? (
                    <Tooltip
                      title={`Pickup Dwelling - ${
                        pickupDwell
                          ? `${pickupDwell} minutes (${formatPickupSuccessful} to ${formatDeliveryStarted})`
                          : `0m`
                      }`}
                    >
                      <div className={barCls.p3} />
                    </Tooltip>
                  ) : null}

                  <Grid container justifyContent='space-between'>
                    <Grid item xs={4}>
                      <Typography className={cls.timeTxt} style={{ textAlign: `left` }}>
                        Drive: {pickupDrive ? `${pickupDrive}m` : `0m`}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography className={cls.timeTxt} style={{ textAlign: `center` }}>
                        Inspect: {pickupInspect ? `${pickupInspect}m` : `0m`}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography className={cls.timeTxt} style={{ textAlign: `right` }}>
                        Dwell: {pickupDwell ? `${pickupDwell}m` : `0m`}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>

                <Spacer size='md' />

                <div className={cls.block}>
                  <Typography className={cls.headTxt}>Delivery Details</Typography>

                  <Tooltip placement='top' title='Click to view details for this location'>
                    <div className={cls.location} onClick={() => goToLocationDetails(delivery.id)}>
                      <Typography className={cls.locationTxt}>{delivery.name}</Typography>
                      <Typography className={cls.locationTxt}>{delivery.address}</Typography>
                    </div>
                  </Tooltip>

                  {deliveryDrive || deliveryTotal === 0 ? (
                    <Tooltip
                      title={`Delivery Drive - ${
                        deliveryDrive
                          ? `${deliveryDrive} minutes (${formatDeliveryStarted} to ${formatDeliveryArrived})`
                          : `0m`
                      }`}
                    >
                      <div className={barCls.d1} />
                    </Tooltip>
                  ) : null}

                  {deliveryInspect || deliveryTotal === 0 ? (
                    <Tooltip
                      title={`Delivery Inspection - ${
                        deliveryInspect
                          ? `${deliveryInspect} minutes (${formatDeliveryArrived} to ${formatDeliverySuccessful})`
                          : `0m`
                      }`}
                    >
                      <div className={barCls.d2} />
                    </Tooltip>
                  ) : null}

                  <Grid container justifyContent='space-between'>
                    <Grid item xs={4}>
                      <Typography className={cls.timeTxt} style={{ textAlign: `left` }}>
                        Drive: {deliveryDrive ? `${deliveryDrive}m` : `0m`}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography className={cls.timeTxt} style={{ textAlign: `center` }}>
                        Inspect: {deliveryInspect ? `${deliveryInspect}m` : `0m`}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography className={cls.timeTxt} style={{ textAlign: `right` }}>
                        Total: {totalTime ? `${totalTime}m` : `0m`}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={cls.section}>
                <Typography className={cls.headTxt}>Vehicle Details</Typography>

                <Spacer size='sm' />

                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Vehicle:&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>{getFormattedVehicleFromMove(move) || `-`}</Typography>
                </div>
                <div className={cls.lineBreak} />
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Stock:&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>{move.vehicle_stock || `-`}</Typography>
                </div>
                <div className={cls.lineBreak} />
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>VIN:&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>{move.vehicle_vin || `-`}</Typography>
                </div>

                <Spacer size='md' />

                <Typography className={cls.headTxt}>Rate Details</Typography>

                <Spacer size='sm' />

                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Rate&nbsp;Class:&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>{capFirst(move.class)}</Typography>
                </div>
                <div className={cls.lineBreak} />
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Rate&nbsp;Rule:&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>
                    {move.raterule && move.raterule.type
                      ? `${move.raterule.type === 'per' ? 'Per Mile' : capFirst(move.raterule.type)}`
                      : `N/A`}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={cls.sectionDark}>
                <Typography className={cls.headTxt}>Charge Details</Typography>

                <Tooltip placement='top' title={`Actions`}>
                  <IconButton className={cls.iconBtn} onClick={handleActionsOpen}>
                    <Icon>settings</Icon>
                  </IconButton>
                </Tooltip>
                <Menu
                  keepMounted
                  id={`armove-actions-menu`}
                  anchorEl={actionsOpen}
                  open={Boolean(actionsOpen)}
                  onClose={handleActionsClose}
                >
                  {actions.map((action, i) =>
                    !action.hide ? (
                      <MenuItem key={`armove-action-${i}`} onClick={() => handleAction(action)}>
                        {action.label || `Action ${i + 1}`}
                      </MenuItem>
                    ) : null
                  )}
                </Menu>

                {revisions.length > 0 && (
                  <>
                    <Spacer />
                    <TextField
                      select
                      fullWidth
                      label='Revision'
                      size='small'
                      variant='outlined'
                      value={revisionNum}
                      onChange={event => setRevisionNum(event.target.value)}
                    >
                      <MenuItem value={currentRevisionNum}>Current Revision #{currentRevisionNum}</MenuItem>
                      {revisions.map(item => (
                        <MenuItem key={item.revision} value={item.revision}>
                          Revision #{item.revision}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                )}

                <Spacer />

                {lineItems.length > 0 &&
                  lineItems.map((item, i) => (
                    <React.Fragment key={`selected-revision-detail-${i}`}>
                      <div className={cls.block}>
                        <Typography className={cls.chargeNameTxt}>{capFirst(item.name)}</Typography>
                        <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
                          <Grid item xs>
                            <Typography className={cls.chargeNotesTxt}>{capFirst(item.notes)}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography className={cls.chargeAmountTxt}>{item.amount}</Typography>
                          </Grid>
                        </Grid>
                      </div>
                      <div className={cls.lineBreak} />
                    </React.Fragment>
                  ))}

                {selectedRevision && selectedRevision.discount_amount > 0 && (
                  <>
                    <div className={cls.block}>
                      <Typography className={cls.chargeNameTxt}>Discount</Typography>
                      <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
                        <Grid item xs>
                          <Typography className={cls.chargeNotesTxt}>
                            {capFirst(selectedRevision && selectedRevision.discount_reason)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={cls.chargeAmountTxt}>
                            (
                            {selectedRevision && selectedRevision.discount_amount
                              ? getMonetaryValue(selectedRevision.discount_amount)
                              : getMonetaryValue(0)}
                            )
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={cls.lineBreak} />
                  </>
                )}

                {lineItems.length > 0 || (selectedRevision && selectedRevision.discount_amount > 0) ? (
                  <Spacer size='xs' />
                ) : null}

                <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
                  <Grid item xs>
                    {selectedRevision && selectedRevision.disputed ? (
                      <>
                        <div className={cls.notesBox}>
                          <Typography className={cls.notesKeyTxt}>Disputed&nbsp;Reason:</Typography>
                          <Typography className={cls.notesValTxt}>
                            {capFirst(selectedRevision && selectedRevision.dispute_reason)}
                          </Typography>
                        </div>
                      </>
                    ) : null}
                  </Grid>

                  <Grid item>
                    <Typography className={cls.totalsKeyTxt}>Status:&nbsp;&nbsp;&nbsp;</Typography>

                    <Spacer size='xs' />

                    <Typography className={cls.totalsKeyTxt}>Total:&nbsp;&nbsp;&nbsp;</Typography>
                    <Typography className={cls.totalsKeyTxt}>Amount&nbsp;Paid:&nbsp;&nbsp;&nbsp;</Typography>
                    <Typography className={cls.totalsKeyTxt}>Amount&nbsp;Due:&nbsp;&nbsp;&nbsp;</Typography>
                  </Grid>

                  <Grid item>
                    <Typography className={cls.totalsValTxt}>
                      {due <= 0 ? `Paid` : paid <= 0 ? `Unpaid` : `Partial`}
                    </Typography>

                    <Spacer size='xs' />

                    <Typography className={cls.totalsValTxt}>{getMonetaryValue(clampNegNum(total))}</Typography>
                    <Typography className={cls.totalsValTxt}>{getMonetaryValue(clampNegNum(paid))}</Typography>
                    <Typography className={cls.totalsValTxt}>{getMonetaryValue(clampNegNum(due))}</Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  cell: {
    padding: '0 !important',
    border: 'none',
  },
  collapse: {
    borderBottom: theme.border[0],
  },
  section: {
    position: 'relative',
    height: '100%',
    padding: theme.spacing(2),
    background: theme.palette.background.light,
  },
  sectionDark: {
    position: 'relative',
    height: '100%',
    padding: theme.spacing(2),
    borderLeft: theme.border[0],
    background: theme.palette.background.dark,
  },
  headTxt: {
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 500,
  },
  location: {
    padding: theme.spacing(1),
    borderRadius: `${theme.shape.borderRadius} ${theme.shape.borderRadius} 0 0`,
    '&:hover, &:focus': {
      background: theme.palette.action.hover,
    },
    cursor: 'pointer',
    transition: '0.15s',
  },
  locationTxt: {
    lineHeight: 1,
    fontSize: 12,
  },
  timeTxt: {
    marginTop: theme.spacing(-1),
    lineHeight: 1,
    fontSize: 10,
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 12,
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 12,
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    margin: '4px 0',
    background: theme.palette.divider,
  },
  iconBtn: {
    position: 'absolute',
    top: 4,
    right: 4,
    padding: theme.spacing(1),
  },
  chargeNameTxt: {
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 500,
  },
  chargeNotesTxt: {
    lineHeight: 1.25,
    fontSize: 10,
  },
  chargeAmountTxt: {
    lineHeight: 1.25,
    fontSize: 10,
  },
  notesBox: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.paperRadius,
    marginRight: theme.spacing(2),
    lineHeight: 1.25,
    background: theme.palette.action.hover,
  },
  notesKeyTxt: {
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 500,
  },
  notesValTxt: {
    lineHeight: 1.25,
    fontSize: 10,
  },
  totalsKeyTxt: {
    lineHeight: 1.25,
    fontSize: 10,
  },
  totalsValTxt: {
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 10,
    fontWeight: 600,
  },
}));
