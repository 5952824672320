//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Icon,
  MenuItem,
  Chip,
} from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { useData } from '../../../DataProvider';
import { useUserDetails } from './UserDetailsProvider';
import { getUserRole } from '../../utils/authHelper';
import ReusableMultiselect from '../../reusable/selectors/ReusableMultiselect';
import AllowedCustomersTable from './AllowedCustomers/AllowedCustomersTable';

const titleGridWidth = 5;

//////////////////////// COMPONENT ////////////////////////

export default function UserDetailsForm() {
  const cls = useStyles();
  const ctx = useUserDetails();

  const [currentUserRole, setCurrentUserRole] = React.useState(null);
  const [formattedDefaultCustomer, setFormattedDefaultCustomer] = React.useState(null);

  const handleInputChange = setHandler => event => {
    if (setHandler) setHandler(event.target.value);
  };

  const handleSelectedCustomers = selections => {
    ctx.setAllowedCustomers(selections);
  };

  const handleRemoveCustomer = index => {
    ctx.setAllowedCustomers(prevCustomers => {
      return prevCustomers.filter((_, i) => i !== index);
    });

    ctx.setAllowedCustomerIds(prevIds => {
      const updatedIds = [...prevIds];
      updatedIds.splice(index, 1);
      return updatedIds;
    });
  };

  const getCurrentUserRole = async () => {
    const userRole = await getUserRole();
    setCurrentUserRole(userRole);
  };

  React.useEffect(() => {
    getCurrentUserRole();
  }, []);

  React.useEffect(() => {
    if (ctx.customers && ctx.customers.length > 0 && ctx.customerId) {
      let defaultCustomer = ctx.customers.filter(customer => customer.id === ctx.customerId);
      setFormattedDefaultCustomer(defaultCustomer[0].name + ' (#' + defaultCustomer[0].id + ')');
    }
    if (ctx.allowedCustomers && ctx.allowedCustomers.length > 0) {
      let preselectedCustomers = [];
      ctx.allowedCustomers.forEach(customer => {
        preselectedCustomers.push(customer.customer_id ? customer.customer_id : customer.id);
      });
      ctx.setAllowedCustomerIds(preselectedCustomers);
    }
  }, [ctx.allowedCustomers, ctx.customers]);

  return (
    <Container maxWidth='lg'>
      {/* VITALS */}

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            {ctx.editMode ? (
              <Typography className={cls.sectionTitleTxt}>
                Display Name <span className={cls.sectionRequiredTxt}>*</span>
              </Typography>
            ) : (
              <Typography className={cls.sectionTitleTxt}>Display Name</Typography>
            )}
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        {ctx.editMode ? (
          <Grid item xs>
            <div className={cls.sectionR}>
              <TextField
                disabled={ctx.isSaving}
                required
                fullWidth
                multiline
                label='Display Name'
                placeholder='Enter display name...'
                variant='outlined'
                size='small'
                value={ctx.displayName}
                onChange={handleInputChange(ctx.setDisplayName)}
                error={ctx.validationErrors && ctx.validationErrors.displayName}
                helperText={
                  ctx.validationErrors && ctx.validationErrors.displayName ? ctx.validationErrors.displayName : ``
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        person
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Grid>
        ) : (
          <Grid item xs>
            <div className={cls.sectionR}>
              <Typography>{ctx.displayName}</Typography>
            </div>
          </Grid>
        )}
      </Grid>

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            {ctx.editMode ? (
              <Typography className={cls.sectionTitleTxt}>
                Email <span className={cls.sectionRequiredTxt}>*</span>
              </Typography>
            ) : (
              <Typography className={cls.sectionTitleTxt}>Email</Typography>
            )}
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {ctx.editMode ? (
              <TextField
                disabled={ctx.isSaving}
                required
                fullWidth
                multiline
                label='Email'
                placeholder='Enter email address...'
                variant='outlined'
                size='small'
                value={ctx.email}
                onChange={handleInputChange(ctx.setEmail)}
                error={ctx.validationErrors && ctx.validationErrors.email}
                helperText={ctx.validationErrors && ctx.validationErrors.email ? ctx.validationErrors.email : ``}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        mail
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Typography>{ctx.email}</Typography>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          {ctx.editMode ? (
            <div className={cls.sectionL}>
              <Typography className={cls.sectionTitleTxt}>Phone (Optional)</Typography>
            </div>
          ) : (
            <div className={cls.sectionL}>
              <Typography className={cls.sectionTitleTxt}>Phone</Typography>
            </div>
          )}
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {ctx.editMode ? (
              <TextField
                disabled={ctx.isSaving}
                fullWidth
                multiline
                label='Phone'
                placeholder='Enter phone number...'
                variant='outlined'
                size='small'
                value={ctx.phone}
                onChange={handleInputChange(ctx.setPhone)}
                error={ctx.validationErrors && ctx.validationErrors.phone}
                helperText={ctx.validationErrors && ctx.validationErrors.phone ? ctx.validationErrors.phone : ``}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        phone
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Typography>{ctx.phone}</Typography>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          {ctx.editMode ? (
            <div className={cls.sectionL}>
              <Typography className={cls.sectionTitleTxt}>Avatar (Optional)</Typography>
            </div>
          ) : (
            <div className={cls.sectionL}>
              <Typography className={cls.sectionTitleTxt}>Avatar </Typography>
            </div>
          )}
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {ctx.editMode ? (
              <TextField
                disabled={ctx.isSaving}
                fullWidth
                multiline
                label='Avatar'
                placeholder='Enter avatar URL...'
                variant='outlined'
                size='small'
                value={ctx.avatarUrl}
                onChange={handleInputChange(ctx.setAvatarurl)}
                error={ctx.validationErrors && ctx.validationErrors.avatar}
                helperText={ctx.validationErrors && ctx.validationErrors.avatar ? ctx.validationErrors.avatar : ``}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        camera_alt
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Typography>{ctx.avatarUrl}</Typography>
            )}
          </div>
        </Grid>
      </Grid>

      {/* ROLE */}

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            {ctx.editMode ? (
              <Typography className={cls.sectionTitleTxt}>
                User Role <span className={cls.sectionRequiredTxt}>*</span>
              </Typography>
            ) : (
              <Typography className={cls.sectionTitleTxt}>User Role</Typography>
            )}

            <Spacer />
            {ctx.editMode ? (
              <>
                <Typography className={cls.sectionSubtitleTxt}>
                  Select a default role for the user, which will determine their level of access to the customer or
                  organization's rooftops.
                </Typography>
                <Spacer />
                {currentUserRole === 'dealer-super-admin' && (
                  <>
                    <Typography className={cls.sectionSubtitleTxt}>
                      <b>Dealer Super Admin</b> - This user will have full, automatic access to all customers/rooftops
                      within an organization.
                    </Typography>
                    <Spacer />
                  </>
                )}

                {(currentUserRole === 'dealer-super-admin' || currentUserRole === 'dealer-admin') && (
                  <>
                    <Typography className={cls.sectionSubtitleTxt}>
                      <b>Dealer Admin</b> - This user will have access to the subset of customers/rooftops that you will
                      be prompted to specify.
                    </Typography>
                    <Spacer />
                  </>
                )}

                <Typography className={cls.sectionSubtitleTxt}>
                  <b>Dealer</b> - This user will have access to a single customer/rooftop (the default customer) that
                  you will specify below.
                </Typography>
              </>
            ) : (
              <>
                {ctx.role === 'dealer-super-admin' && (
                  <>
                    <Typography className={cls.sectionSubtitleTxt}>
                      This user has full, automatic access to all customers/rooftops within an organization.
                    </Typography>
                    <Spacer />
                  </>
                )}

                {ctx.role === 'dealer-admin' && (
                  <>
                    <Typography className={cls.sectionSubtitleTxt}>
                      This user has access to the subset of customers/rooftops that are listed below.
                    </Typography>
                    <Spacer />
                  </>
                )}
              </>
            )}
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {ctx.editMode ? (
              <TextField
                disabled={ctx.isSaving}
                required
                select
                fullWidth
                label='Role'
                placeholder='Select a role...'
                variant='outlined'
                size='small'
                value={ctx.role}
                onChange={handleInputChange(ctx.setRole)}
                error={ctx.validationErrors && ctx.validationErrors.role}
                helperText={ctx.validationErrors && ctx.validationErrors.role ? ctx.validationErrors.role : ``}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        category
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem
                  disabled={currentUserRole !== 'admin' && currentUserRole !== 'dealer-super-admin'}
                  value='dealer-super-admin'
                >
                  Dealer Super Admin
                </MenuItem>
                <MenuItem
                  value='dealer-admin'
                >
                  Dealer Admin
                </MenuItem>
                <MenuItem value='dealer'>Dealer</MenuItem>
              </TextField>
            ) : (
              <Typography>
                {ctx.role &&
                  ctx.role
                    .split('-')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>

      {/* DEFAULT CUSTOMER */}

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            {ctx.editMode ? (
              <Typography className={cls.sectionTitleTxt}>
                Default Customer <span className={cls.sectionRequiredTxt}>*</span>
              </Typography>
            ) : (
              <Typography className={cls.sectionTitleTxt}>Default Customer</Typography>
            )}

            <Spacer />
            {ctx.editMode ? (
              <>
                    <Typography className={cls.sectionSubtitleTxt}>
                      The primary customer/rooftop with which the new user will be associated. This field is required
                      for all users. If you are creating a user with the "dealer admin" role, you will be prompted to
                      give them permissions for additional rooftops below.
                    </Typography>
              </>
            ) : (
              <Typography>The primary customer/rooftop with which the user is associated.</Typography>
            )}
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {ctx.editMode ? (
              <TextField
                disabled={ctx.isSaving}
                select
                fullWidth
                label='Defalt Customer'
                placeholder='Select the default customer...'
                variant='outlined'
                size='small'
                value={ctx.customerId}
                onChange={handleInputChange(ctx.setCustomerId)}
                error={ctx.validationErrors && ctx.validationErrors.customerId}
                helperText={
                  ctx.validationErrors && ctx.validationErrors.customerId ? ctx.validationErrors.customerId : ``
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        roofing
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              >
                {ctx.customers &&
                  ctx.customers.length &&
                  ctx.customers.map((c, i) => (
                    <MenuItem key={`organization-${c.id}`} value={c.id}>
                      {c.name} (#{c.id})
                    </MenuItem>
                  ))}
              </TextField>
            ) : (
              <Typography>{formattedDefaultCustomer || null}</Typography>
            )}
          </div>
        </Grid>
      </Grid>

      {/* ALLOWED CUSTOMERS */}

      {ctx.role === 'dealer-admin' && (
        <Grid container wrap='nowrap'>
          <Grid item xs={titleGridWidth}>
            <div className={cls.sectionL}>
              {ctx.editMode ? (
                <Typography className={cls.sectionTitleTxt}>
                  Allowed Customers <span className={cls.sectionRequiredTxt}>*</span>
                </Typography>
              ) : (
                <Typography className={cls.sectionTitleTxt}>Allowed Customers</Typography>
              )}

              <Spacer />
              {ctx.editMode ? (
                <Typography className={cls.sectionSubtitleTxt}>
                  Dealer admin users must be given access to at least one customer/rooftop in addition to their default.
                </Typography>
              ) : (
                <Typography>
                  Dealer admin users have access to at least one customer/rooftop in addition to their default.
                </Typography>
              )}
            </div>
          </Grid>

          <Grid item>
            <div className={cls.sectionLine} />
          </Grid>

          <Grid item xs>
            {ctx.editMode ? (
              <div className={cls.sectionR}>
                <ReusableMultiselect
                  dataArray={ctx.customers}
                  setSelectedArray={ctx.setAllowedCustomerIds}
                  selectedArray={ctx.allowedCustomerIds}
                  optionName={'name'}
                  label={'Allowed Customer'}
                  handleOutput={handleSelectedCustomers}
                />

                <Spacer />
                {ctx.allowedCustomers &&
                  ctx.allowedCustomers.length &&
                  ctx.allowedCustomers.map((customer, index) => (
                    <Chip
                      key={index}
                      style={{ margin: 5 }}
                      label={
                        customer && customer.customer && customer.customer.name ? customer.customer.name : customer.name
                      }
                      onDelete={() => handleRemoveCustomer(index)}
                    />
                  ))}
                {ctx.validationErrors && ctx.validationErrors.allowedCustomers && (
                  <Typography variant='body2' color='error'>
                    {ctx.validationErrors.allowedCustomers}
                  </Typography>
                )}
              </div>
            ) : (
              <div className={cls.sectionR} style={{ maxWidth: '60%' }}>
                <AllowedCustomersTable allowedCustomers={ctx.allowedCustomers} />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  sectionL: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionR: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionTitleTxt: {
    fontSize: 16,
    fontWeight: 500,
  },
  sectionRequiredTxt: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.error.main,
  },
  sectionSubtitleTxt: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  sectionLine: {
    width: 1,
    height: '100%',
    background: theme.palette.divider,
  },
  titleChipIcon: {
    fontSize: 20,
    margin: theme.spacing(0, 0.5, 0, 0),
  },
}));
