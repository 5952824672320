//Collection of helper functions for retrieving authorized user info
import sdk from '@hopdrive/sdk';
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

const log = false;

let firebase;

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_PROJECT_ID,
} = process.env;

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const getFirebase = async () => {
  try {
    initializeApp(firebaseConfig)
    firebase = getAuth()
  } catch (err) {
    console.log('Error initializing firebase!', err)
  }
};

const getLatestToken = async () => {
  getFirebase();
  
  try {
    let userId
    let tokenIssued
    let userUpdated
    let firebaseToken = null
    if (firebase && firebase.currentUser) {
      firebaseToken = await firebase.currentUser.getIdTokenResult()
      if (firebaseToken) {
        tokenIssued = new Date(firebaseToken.issuedAtTime)
        userId = firebaseToken.claims['https://hasura.io/jwt/claims']['x-hasura-user-id']
      }

    if (userId) {
      let userUpdateRes = await sdk.gql.query(
        `query getUserUpdatedTime($userId: bigint!) {
          users(where: {id: {_eq: $userId}}) {
            id
            updated_at
          }
        }
      `,
        { userId: userId }
      );

      if (userUpdateRes && userUpdateRes.data && userUpdateRes.data.length > 0) {
        userUpdated = new Date(userUpdateRes.data[0].updated_at)
      }

      if (tokenIssued > userUpdated) {
        firebaseToken = await firebase.currentUser.getIdTokenResult()
      } else if (tokenIssued < userUpdated) {
        firebaseToken = await firebase.currentUser.getIdTokenResult(true)
      }
    }
  }
  return firebaseToken
  } catch (err) {
    console.log('Error getting most up-to-date token', err)
    return null
  }
}

const getAuthStatus = async () => {
  getFirebase();
  if (firebase && firebase.currentUser) {
    return true;
  } else return false;
};

const getUserId = async () => {
  getFirebase();
  if (firebase && firebase.currentUser) {
    let token = await firebase.currentUser.getIdTokenResult();
    let userId = token.claims['https://hasura.io/jwt/claims']['x-hasura-user-id'];
    return userId;
  }
}

const getUserProfile = async () => {
  getFirebase();
  if (firebase && firebase.currentUser) {
    console.log('firebase.currentUser', firebase.currentUser)
    return firebase.currentUser;
  } else return {};
}

const getUserToken = async () => {
  getFirebase();
  if (firebase && firebase.currentUser) {
    let token = await firebase.currentUser.getIdToken();
    return token;
  }
};

const getUserRole = async () => {
  let role;
  getFirebase();
  const firebaseToken = await getLatestToken()
  try {
      role = firebaseToken.claims['https://hasura.io/jwt/claims']['x-hasura-default-role'];
      return role;
  } catch (err) {
    log && console.log('Failed to retrieve user role', err);
    return null;
  }
};

const getUserName = () => {
  getFirebase();
  let name;
  if (firebase && firebase.currentUser) {
    name = firebase.currentUser.displayName;
    return name;
  } else return null;
};

const getUserAvatar = () => {
  getFirebase();
  let avatar;
  if (firebase && firebase.currentUser) {
    avatar = firebase.currentUser.photoURL;
    return avatar;
  } else return null;
};

const getUserEmail = () => {
  getFirebase();
  let email;
  if (firebase && firebase.currentUser) {
    email = firebase.currentUser.email;
    return email;
  } else return null;
};

const getAllowedCustomers = async () => {
  let allowedCustomers;
  const firebaseToken = await getLatestToken()
  try {
    if (firebaseToken && firebaseToken.claims['https://hasura.io/jwt/claims']['x-hasura-allowed-customers']) {
        allowedCustomers = firebaseToken.claims['https://hasura.io/jwt/claims']['x-hasura-allowed-customers'];
        allowedCustomers = allowedCustomers ?  JSON.parse(allowedCustomers.replace('{', '[').replace('}', ']')) : [];
        return allowedCustomers;
    } else return [];
  } catch (err) {
    console.log('Error retrieving allowed customers', err);
    return null;
  }
}

const getAllowedPayers = async () => {
  let allowedPayers;
  const firebaseToken = await getLatestToken()
  try {
    if (firebaseToken) {
      allowedPayers = firebaseToken.claims['https://hasura.io/jwt/claims']['x-hasura-allowed-payers'];
      allowedPayers = allowedPayers ? JSON.parse(allowedPayers.replace('{', '[').replace('}', ']')) : [];
      return allowedPayers;
    } else return null;
  } catch (err) {
    console.log('Error retrieving allowed payers', err);
    return null;
  }
};

const getAllowedPayees = async () => {
  let allowedPayees;
  const firebaseToken = await getLatestToken()
  try {
    if (firebaseToken) {
      allowedPayees = firebaseToken.claims['https://hasura.io/jwt/claims']['x-hasura-payer-child-customers'];
      allowedPayees = allowedPayees ? JSON.parse(allowedPayees.replace('{', '[').replace('}', ']')) : [];
      return allowedPayees;
    } else return null;
  } catch (err) {
    console.log('Error retrieving allowed payees', err);
    return null;
  }
};

const getAllowedRegions = async () => {
  let allowedRegions;
  const firebaseToken = await getLatestToken()
  try {
    if (firebaseToken) {
      allowedRegions = firebaseToken.claims['https://hasura.io/jwt/claims']['x-hasura-allowed-regions'];
      return allowedRegions;
    } else return null;
  } catch (err) {
    console.log('Error retrieving allowed regions', err);
    return null;
  }
};

const getAllowedRoles = async () => {
  let allowedRoles;
  const firebaseToken = await getLatestToken()
  try {
    if (firebaseToken) {
      allowedRoles = firebaseToken.claims['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'];
      return allowedRoles;
    } else return null;
  } catch (err) {
    console.log('Error retrieving allowed roles', err);
    return null;
  }
};

const getCustomerId = async () => {
  let customerId
  const firebaseToken = await getLatestToken()
  try {
    if (firebaseToken) {
      customerId = firebaseToken.claims['https://hasura.io/jwt/claims']['x-hasura-customer-id'];
      return customerId
    } else return null
  } catch (err) {
    console.log('Error retrieving customer id', err)
  }

}

export {
  getUserRole,
  getUserName,
  getUserAvatar,
  getUserEmail,
  getFirebase,
  getUserToken,
  getLatestToken,
  getUserProfile,
  getAllowedPayers,
  getAllowedRegions,
  getAuthStatus,
  getAllowedRoles,
  getAllowedCustomers,
  getCustomerId,
  getAllowedPayees,
  getUserId
};
